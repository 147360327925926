import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Router from './router';
import AuthProvider from './context/AuthProvider';
import echo from './echo';

const App = () => {
  useEffect(() => {
    // Substitua 'chat' pelo nome do seu canal
    echo.channel('chat')
      .listen('MessageSent', (e) => {
        const regex = /(\d+)\s+(\d+)$/;
        const match = e.message.match(regex);
        const admin_id = match[1];
        const user_id = match[2];
        const newText = e.message.replace(regex, '').trim();
        console.log('admin_id:', admin_id); 
        console.log('user_id:', user_id);
        console.log('newText:', newText);
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'scheduleStore',
            data: e.message
          }));
        }
   
      });

    // Cleanup function to unsubscribe from the channel when component unmounts
    return () => {
      echo.leaveChannel('chat');
    };
  }, []);

  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
