class Validator {
    constructor(rules) {
      this.rules = rules;
      this.messages = {};
    }
  
    validate(data) {
      let errors = {};
  
      // Verifica primeiro contra injeção de SQL para todos os campos
      for (let field in data) {
        if (!this.isSafeFromSqlInjection(data[field])) {
          errors[field] = `${field} contém caracteres inválidos que podem ser usados para ataques de injeção SQL.`;
          return errors; // Retorna imediatamente se qualquer campo falhar nesta verificação
        }
      }
  
      // Aplica outras regras de validação se passar pela verificação de injeção de SQL
      for (let field in this.rules) {
        const fieldRules = this.rules[field].split('|');
        for (let rule of fieldRules) {
          const [ruleName, parameter] = rule.includes(':') ? rule.split(':') : [rule, ''];
          if (!this.applyRule(data[field], ruleName, parameter)) {
            errors[field] = this.getErrorMessage(field, ruleName, parameter);
            break; // Interrompe após o primeiro erro para este campo
          }
        }
      }
  
      return errors;
    }
  
    isSafeFromSqlInjection(value) {
      const sqlInjectionPattern = /(')|(;)|(\b(SELECT|UPDATE|DELETE|INSERT)\b)/i;
      return typeof value === 'string' ? !sqlInjectionPattern.test(value) : true;
    }
  
  
    applyRule(value, rule, parameter) {
      switch (rule) {
        case 'required':
          return value !== undefined && value !== null && value !== '';
        case 'email':
          return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
        case 'min':
          if (!isNaN(value)) {
            return parseFloat(value) >= parseFloat(parameter);
          }
          return value.length >= parseInt(parameter, 10);
        case 'max':
          if (!isNaN(value)) {
            return parseFloat(value) <= parseFloat(parameter);
          }
          return value.length <= parseInt(parameter, 10);
        case 'regex':
          let regex = new RegExp(parameter);
          return regex.test(value);
        case 'noSqlInjection':
          // Verifica a presença de padrões comuns usados em injeções SQL
          const sqlInjectionPattern = /(')|(;)|(\b(SELECT|UPDATE|DELETE|INSERT)\b)/i;
          return !sqlInjectionPattern.test(value);

        // Adicione mais regras conforme necessário
        default:
          return true;
      }
    }
  
    getErrorMessage(field, rule, parameter) {
      const messages = {
        required: `${field} é obrigatório.`,
        email: `${field} deve ser um e-mail válido.`,
        min: `${field} deve ter no mínimo ${parameter} caracteres.`,
        max: `${field} deve ter no máximo ${parameter} caracteres.`,
        regex: `${field} tem um formato inválido.`,
        // Adicione mais mensagens conforme necessário
      };
      return this.messages[field + '.' + rule] || messages[rule];
    }
  
    setCustomMessages(messages) {
      this.messages = messages;
    }



  isHourValid(hours, officeHours) {
      // Função para converter horário em minutos para comparação
      function timeToMinutes(time) {
          if (!time || time === "") return null;
          const [hours, minutes] = time.split(":").map(Number);
          return hours * 60 + minutes;
      }
  
      for (let hour of hours) {
          // Encontrar o dia correspondente em officeHours
          let officeHour = null;
          if(officeHours){
             officeHour = officeHours?.find(oh => oh.name === hour.name);
          }
          // Se o dia não for encontrado em officeHours, retorna inválido
          if (!officeHour) {
              console.log("Dia não encontrado em officeHours:", hour.name);
              return false;
          }
  
          // Se não houver horário agendado em 'hours', considera válido
          if ((!hour.start && hour.start !== 0) || (!hour.end && hour.end !== 0)) {
              continue;
          }
  
          // Se officeHour.status for falso, retorna inválido
          /*
          if (!officeHour.status) {
              console.log("Tentativa de agendar em um dia fechado:", hour.name);
              return false;
          }
          */
  
          const hourStart = timeToMinutes(hour.start);
          const hourEnd = timeToMinutes(hour.end);
          const officeStart = timeToMinutes(officeHour.start);
          const officeEnd = timeToMinutes(officeHour.end);
  
          // Verificar se os horários estão dentro do intervalo permitido
          if (hourStart < officeStart || hourEnd > officeEnd) {
              console.log("Horário fora do intervalo permitido:", hour.name);
              return false;
          }
      }
  
      return true;
  }
}
  

  export default Validator;
