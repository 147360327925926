import axios from "axios";
import { API_URL } from "../../config";

export async function getCollaborators(id)
{
    const response = axios.get(`${API_URL}/colaborators?adminId=${id}`);

    return (await response).data
}

export async function getAllBilling(adminId, month, year)
{
    const response = axios.get(`${API_URL}/billig-all?adminId=${adminId}&month=${month}&year=${year}`);

    console.log(`${API_URL}/billig-all?adminId=${adminId}&month=${month}&year=${year}`);

    return (await response).data
}

export async function getCollaboratorBilling(collaboratorId, month, year)
{
    const response = axios.get(`${API_URL}/billig-collaborator?collaboratorId=${collaboratorId}&month=${month}&year=${year}`);

    console.log(`${API_URL}/billig-all?adminId=${collaboratorId}&month=${month}&year=${year}`);

    return (await response).data
}