import { ButtonTransparent, Spacing, Text } from "../../share/componets/styles";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { useEffect, useState, useRef, useReducer } from "react";
import { useNavigate } from "react-router-dom";

import {

    ButtonScheduling,
    Container,
    ContainerCenter,
    ContainerEnd,
    ContainerHorizontal,
    ContainerStart,
    GlobalContainer,
    InputScheduling,
    CardServices,
    ContainerFlex,
    CardDays,
    ContainerRoudend,
    CardTime,
    StyledTelefoneInput,
    ButtonMySchedulingTop,
    JaddeAvatar,
    ContainerFlexBetween,

} from "./styled";

import {
    formatarData,
    generateDaysList,
    genereteTimes,
    getCode,
    getData,
    getRecurrenceByClientId,
    getSchedulingByEmailByPhone,
    listServices,
    storeScheduling,
    transformStringDate,
    verifieldCheckBoxHour
} from "./functions";

import Reload from "../../share/componets/reload";

import { Concluded } from "./container/concluded";
import Block from "./container/block";

export function Scheduling() {
    const navigate = useNavigate();
    const initialState = {
        reload: true,
        concluded: false,
        block: false,
        lineOne: false,
        lineOne1: false,
        lineSixteenData: false,
        lineTwo: false,
        lineThree: false,
        lineFour: false,
        lineTwo1: false,
        lineThree1: false,
        lineFour1: false,
        lineTwo2: false,
        lineThree2: false,
        lineFour2: false,
        lineFive: false,
        lineFive1: false,
        lineFive2: false,
        lineSix: false,
        lineSix1: false,
        lineSix2: false,
        lineSixData: false,
        lineSixRemake: false,
        recurrenceStart: false,
        lineSeven: false,
        lineEight: false,
        lineNine: false,
        lineTen: false,
        lineTenBloqued: false,
        lineEleven: false,
        lineTwelve: false,
        lineThirteen: false,
        lineThirteenButton: false,
        lineThirteenMensage: false,
        lineFourteen: false,
        lineFourteenButton: false,
        lineFifteen: false,
        lineSixteen: false,
        lineSeventeenData: false,
        lineSeventeen: false,
        name: '',
        phone: '',
        email: '',
        servicesList: [],
        checkboxservices: [],
        daysList: [],
        checkboxday: null,
        checkBoxHour: null,
        collaborator: { name: '' },
        timesG: [],
        schedulingResult: null,
        hour: null,
        schedulings: [],
        collaboratorDaysHoursG: [],
        officeHour: {},
        codeG: null,
        intervals: null,
        daysLimited: 0
    };
    const [state, dispatch] = useReducer(reducer, initialState);
    function reducer(state, action) {
        switch (action.type) {
            case 'SET_STATE':
                return { ...state, ...action.payload };
            default:
                return state;
        }
    }

    const scrollRef = useRef(null);

    const fetchData = async () => {
        const code = getCode();
        dispatch({ type: 'SET_STATE', payload: { codeG: code } });
        if (code) {
            const DATA = await getData(code);
    
            if (DATA) {
                const collaboratorDaysHoursG = JSON.parse(DATA.collaborator.days_hours);
                const servicesList = JSON.parse(DATA.services);
                const collaborator = DATA.collaborator;
                const schedulings = DATA.schedulings;
                const officeHour = DATA.officeHour;
                const intervals = DATA.collaborator.intervals ? JSON.parse(DATA.collaborator.intervals) : [];
                const daysLimited = DATA.officeHour.days_limited_schedulings ? DATA.officeHour.days_limited_schedulings : 0;
                const daysList = generateDaysList(collaboratorDaysHoursG, daysLimited);

   

                dispatch({
                    type: 'SET_STATE',
                    payload: {
                        servicesList,
                        daysList,
                        collaborator,
                        schedulings,
                        collaboratorDaysHoursG,
                        officeHour,
                        code,
                        intervals,
                        daysLimited,
                        reload: false
                    }
                });

                const verifieldData = handleLocalStorageData();

                if (verifieldData) {

                    setTimeout(() => {

                        dispatch({ type: 'SET_STATE', payload: { lineSixData: true } });
                    }, 2000);

                    if ("Notification" in window) {
                        if (Notification.permission === "granted") {
                            setTimeout(() => {
                                dispatch({ type: 'SET_STATE', payload: { lineTwelve: true } });
                            }, 3000);
                        } else {
                            setTimeout(() => {
                                dispatch({ type: 'SET_STATE', payload: { lineSeven: true } });
                            }, 3000);

                            setTimeout(() => {
                                dispatch({ type: 'SET_STATE', payload: { lineEight: true, lineNine: true } });
                            }, 4000);
                        }
                    } else {
                        setTimeout(() => {
                            dispatch({ type: 'SET_VISIBLE', payload: { lineTwelve: true } });
                        }, 3000);
                    }


                    return
                }
                setTimeout(() => {
                    dispatch({ type: 'SET_STATE', payload: { lineOne: true } });
                }, 2000);

                setTimeout(() => {
                    dispatch({ type: 'SET_STATE', payload: { lineOne1: true } });
                }, 3000);

                setTimeout(() => {
                    dispatch({ type: 'SET_STATE', payload: { lineTwo: true } });
                }, 4000);


                setTimeout(() => {
                    dispatch({ type: 'SET_STATE', payload: { lineThree: true, lineFour: true } });
                }, 5000);

            }
        }
    }

    useEffect(() => {

        fetchData();

    }, [])
    const {
        concluded,
        reload,
        block,
        lineOne,
        lineOne1,
        lineSixteenData,
        lineTwo,
        lineThree,
        lineFour,
        lineTwo1,
        lineThree1,
        lineFour1,
        lineTwo2,
        lineThree2,
        lineFour2,
        lineFive,
        lineFive1,
        lineFive2,
        lineSix,
        lineSix1,
        lineSix2,
        lineSixData,
        lineSixRemake,
        recurrenceStart,
        lineSeven,
        lineEight,
        lineNine,
        lineTen,
        lineTenBloqued,
        lineEleven,
        lineTwelve,
        lineThirteen,
        lineThirteenButton,
        lineThirteenMensage,
        lineFourteen,
        lineFourteenButton,
        lineFifteen,
        lineSixteen,
        lineSeventeenData,
        lineSeventeen,
        name,
        phone,
        email,
        servicesList,
        checkboxservices,
        daysList,
        checkboxday,
        checkBoxHour,
        collaborator,
        timesG,
        schedulingResult,
        hour,
        schedulings,
        collaboratorDaysHoursG,
        officeHour,
        codeG,
        intervals,
        daysLimited
    } = state;
    function handleLocalStorageData() {
        const chatData = JSON.parse(localStorage.getItem('chat'));

        if (chatData) {


            dispatch({
                type: 'SET_STATE', payload:
                {
                    name: chatData.name,
                    phone: chatData.phone,
                    email: chatData.email
                }
            });
            return true;

        } else {

            console.log('Nenhum dado de chat encontrado.');

            return false;
        }
    }


    function sendName() {
        dispatch({
            type: 'SET_STATE', payload:
            {
                lineThree: false,
                lineFour: false,
                lineFive: true
            }
        });
        setTimeout(() => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineTwo1: true,
                    lineThree1: true,
                    lineFour1: true,
                }
            });
        }, 2000);


    }

    function sendPhone() {
        setTimeout(() => {


            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineThree1: false,
                    lineFour1: false,
                    lineFive1: true
                }
            });
        }, 2000);

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineTwo2: true,
                    lineThree2: true,
                    lineFour2: true,
                }
            });
        }, 3000);
    }

    async function sendEmail() {

        setTimeout(() => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineThree2: false,
                    lineFour2: false,
                    lineFive2: true
                }
            });
        }, 2000);


        setTimeout(async () => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    reload: true

                }
            });

            const client = await getSchedulingByEmailByPhone(email, phone);

            if (client.length !== 0) {
                if (client[0].client.block_at !== null) {
                    dispatch({
                        type: 'SET_STATE', payload:
                        {
                            block: true
                        }
                    });

                    dispatch({
                        type: 'SET_STATE', payload:
                        {
                            reload: false

                        }
                    });


                    return
                }

                const recurrence = await getRecurrenceByClientId(client[0].client_id, collaborator.id);



                dispatch({
                    type: 'SET_STATE', payload:
                    {
                        name: client[0].client.person.name

                    }
                });

                const data = {
                    name: client[0].client.person.name,
                    phone: phone,
                    email: email,
                    collaborator: collaborator,
                    schedulings: client
                };

                localStorage.setItem('chat', JSON.stringify(data));


                dispatch({
                    type: 'SET_STATE', payload:
                    {
                        reload: false

                    }
                });

                setTimeout(() => {

                    dispatch({
                        type: 'SET_STATE', payload:
                        {
                            reload: false

                        }
                    });
                    dispatch({
                        type: 'SET_STATE', payload: {
                            lineOne: false,
                            lineOne1: false,
                            lineTwo: false,
                            lineThree: false,
                            lineFour: false,
                            lineFive: false,

                            lineTwo1: false,
                            lineThree1: false,
                            lineFour1: false,
                            lineFive1: false,

                            lineTwo2: false,
                            lineThree2: false,
                            lineFour2: false,
                            lineFive2: false

                        }
                    });

                }, 1000);

                //RECORRENCIA START


                setTimeout(() => {


                    dispatch({
                        type: 'SET_STATE', payload:
                        {
                            recurrenceStart: recurrence.id ? true : false,
                            lineSixData: true,
                            lineTwelve: true,
                        }
                    });
                }, 2000);


            } else {

                console.log('Não existe cliente');
                dispatch({
                    type: 'SET_STATE', payload:
                    {
                        lineSix: true,
                        reload: false
                    }
                });
                setTimeout(() => {

                    dispatch({
                        type: 'SET_STATE', payload:
                        {
                            lineTwelve: true,
                        }
                    });
                }, 1000);

            }

        }, 3000)
    }

    function permissionNotification() {
        if ("Notification" in window) {
            if (Notification.permission === "granted") {
                // Permissão já concedida, enviar notificações
            } else if (Notification.permission === "denied") {
                // Permissão negada, instrua o usuário a abrir as configurações do navegador
                alert("As notificações estão bloqueadas. Para ativá-las, acesse as configurações do seu navegador.");
            } else {
                // Ainda não foi solicitada permissão, solicite-a como mencionado anteriormente
                Notification.requestPermission().then(permission => {
                    console.log("permission", permission)
                    if (permission === "granted") {


                        dispatch({
                            type: 'SET_STATE', payload:
                            {
                                lineEight: false,
                                lineNine: false
                            }
                        });
                        setTimeout(() => {


                            dispatch({
                                type: 'SET_STATE', payload:
                                {
                                    lineTen: true
                                }
                            });
                        }, 1000);

                        setTimeout(() => {

                            dispatch({
                                type: 'SET_STATE', payload:
                                {
                                    lineTwelve: true
                                }
                            });
                        }, 2000);


                    } else if (permission === "denied") {


                        dispatch({
                            type: 'SET_STATE', payload:
                            {
                                lineEight: false,
                                lineNine: false
                            }
                        });
                        setTimeout(() => {

                            dispatch({
                                type: 'SET_STATE', payload:
                                {
                                    lineTenBloqued: true
                                }
                            });
                        }, 1000);

                        setTimeout(() => {

                            dispatch({
                                type: 'SET_STATE', payload:
                                {
                                    lineTwelve: true
                                }
                            });
                        }, 2000);
                    }
                });
            }
        }
    }


    function shipPermissionNotification() {
        dispatch({
            type: 'SET_STATE', payload:
            {
                lineEight: false,
                lineNine: false
            }
        });
        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineEleven: true
                }
            });

        }, 1000);

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineTwelve: true
                }
            });
        }, 2000);
    }

    function changeCheckBoxServices(item) {

        console.log(item);

        const SERVICES = [...checkboxservices];

        const index = SERVICES.indexOf(item);

        if (index === -1) {

            SERVICES.push(item);

        } else {

            SERVICES.splice(index, 1);
        }

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    checkboxservices: SERVICES
                }
            });

        }, 1000);
        if (SERVICES.length > 0) {
            setTimeout(() => {
                dispatch({
                    type: 'SET_STATE', payload:
                    {
                        lineThirteenButton: true
                    }
                });
            }, 1000);

            setTimeout(() => {
                const scrollElement = scrollRef.current;

                if (scrollElement) {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                }
            }, 2000);

            return
        }

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineThirteenButton: false
                }
            });
        }, 1000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 2000);

    }


    function sendServices() {

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineTwelve: false,
                    lineThirteenButton: false
                }
            });
        }, 1000);

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineThirteenMensage: true
                }
            });

        }, 2000);

        setTimeout(() => {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineThirteen: true
                }
            });
        }, 3000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 4000);
    }

    function changeCheckBoxDay(item) {
        
        if (!item.available) {
            return
        }
        
        function sumDurations(services) {//Soma a duração dos serviços
            return services.reduce((total, service) => total + parseInt(service.duration), 0);
        }

        const totalDuration = sumDurations(servicesList);

        const times = genereteTimes(
            item,
            collaboratorDaysHoursG,
            schedulings,
            officeHour,
            intervals,
            totalDuration
        );
        if (Array.isArray(times)) {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    checkboxday: item.date,
                    timesG: times
                }
            });
            setTimeout(() => {

                dispatch({
                    type: 'SET_STATE', payload:
                    {
                        lineFourteen: true
                    }
                });
            }, 1000);

            setTimeout(() => {

                const scrollElement = scrollRef.current;

                if (scrollElement) {
                    scrollElement.scrollTop = scrollElement.scrollHeight;
                }
            }, 2000);
        } else {
            dispatch({
                type: 'SET_STATE', payload:
                {
                    timesG: [],
                    checkboxday: item.date
                }
            });
        }

        return


    }

    function changeCheckBoxHour(item) {

        if (!item.available) {
            return
        }

        if (!verifieldCheckBoxHour(item.available, item.slot, checkboxday)) {
            return
        }

        dispatch({
            type: 'SET_STATE', payload:
            {
                checkBoxHour: item.slot
            }
        });
        setTimeout(() => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineFourteenButton: true
                }
            });
        }, 1000);
    }


    function sendHour() {

        setTimeout(() => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineFourteenButton: false,
                    lineFourteen: false,
                    lineThirteen: false
                }
            });

        }, 1000);

        const date = formatarData(checkboxday);

        dispatch({
            type: 'SET_STATE', payload:
            {
                schedulingResult: `${date} às ${checkBoxHour}`
            }
        });
        setTimeout(() => {

            dispatch({
                type: 'SET_STATE', payload:
                {
                    lineSeventeen: true
                }
            });
        }, 2000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 3000);

        setTimeout(() => {

            const scrollElement = scrollRef.current;

            if (scrollElement) {
                scrollElement.scrollTop = scrollElement.scrollHeight;
            }
        }, 4000);
    }

    function remake() {
        dispatch({
            type: 'SET_STATE', payload: {

                lineOne: false,
                lineOne1: false,

                lineTwo: false,
                lineThree: false,
                lineFour: false,
                lineTwo1: false,
                lineThree1: false,
                lineFour1: false,
                lineTwo2: false,
                lineThree2: false,
                lineFour2: false,

                lineFive: false,
                lineFive1: false,
                lineFive2: false,

                lineSix: false,
                lineSix1: false,
                lineSix2: false,

                lineSixRemake: true,
                lineSeven: false,
                lineEight: false,
                lineNine: false,
                lineTen: false,
                lineTenBloqued: false,

                lineEleven: false,
                lineTwelve: true,
                lineThirteen: false,
                lineThirteenButton: false,
                lineThirteenMensage: false,
                lineFourteen: false,
                lineFourteenButton: false,
                lineFifteen: false,
                lineSixteen: false,
                lineSixteenData: false,
                lineSeventeenData: false,
                lineSeventeen: false,
            }
        });

        dispatch({
            type: 'SET_STATE', payload:
            {
                checkboxservices: [],
                checkboxday: null,
                checkBoxHour: null,
                timesG: [],
                schedulingResult: null,
                hour: null,
            }
        });

    }


    function clear() {
        dispatch({
            type: 'SET_STATE', payload: {

                lineOne: false,
                lineOne1: false,

                lineTwo: false,
                lineThree: false,
                lineFour: false,
                lineTwo1: false,
                lineThree1: false,
                lineFour1: false,
                lineTwo2: false,
                lineThree2: false,
                lineFour2: false,

                lineFive: false,
                lineFive1: true,
                lineFive2: true,

                lineSix: false,
                lineSix1: false,
                lineSix2: false,

                lineSixData: false,
                lineSixRemake: false,
                lineSeven: false,
                lineEight: false,
                lineNine: false,
                lineTen: false,
                lineTenBloqued: false,

                lineEleven: false,
                lineTwelve: false,
                lineThirteen: false,
                lineThirteenButton: false,
                lineThirteenMensage: false,
                lineFourteen: false,
                lineFourteenButton: false,
                lineFifteen: false,
                lineSixteen: false,
                lineSeventeenData: false,
                lineSeventeen: false,
            }
        });

        dispatch({
            type: 'SET_STATE', payload:
            {
                checkboxservices: [],
                checkboxday: null,
                checkBoxHour: null,
                timesG: [],
                schedulingResult: null,
                hour: null,
            }
        });
    }

    async function onSave() {


        dispatch({
            type: 'SET_STATE', payload:
            {
                reload: true,


            }
        });

        //STORE NUVEM

        const code = crypto.randomUUID();

        const DATA = {

            name: name,
            phone: phone,
            email: email,
            services: checkboxservices,
            day: checkboxday,
            time: checkBoxHour,
            hour: { date: transformStringDate(checkboxday), time: checkBoxHour },
            code: code,
            collaborator: collaborator,
            type: 'web'
        }

        const store = await storeScheduling(DATA);

        //STORE LOCAL

        const newScheduling = {

            services: checkboxservices,
            day: checkboxday,
            hour: checkBoxHour,
            code: code,
            status: 'Pendente'
        };

        // Tentar recuperar dados existentes do localStorage
        const existingData = localStorage.getItem('chat');
        let data;

        if (existingData) {

            // Se houver dados existentes, parseia e adiciona o novo agendamento
            data = JSON.parse(existingData);
            data.schedulings.push(newScheduling);

        } else {

            // Se não houver dados, cria uma nova estrutura de dados
            data = {
                name: name,
                phone: phone,
                email: email,
                collaborator: collaborator,
                schedulings: [newScheduling]
            };
        }

        // Salva os dados atualizados no localStorage
        localStorage.setItem('chat', JSON.stringify(data));

        setTimeout(() => {

            clear();

            dispatch({
                type: 'SET_STATE', payload:
                {
                    reload: false,
                    concluded: true

                }
            });
        }, 3000);

    }

    function formatDate(dateStr) {
        const [year, month, day] = dateStr.split("-");
        return `${day}/${month}`;
    }


    if (concluded) {
        return (
            <Concluded
                code={codeG}
                email={email}
                name={name}
                collaboratorName={collaborator.name}
            />
        )

    } else {
        if (block) {
            return (
                <Block />
            )
        } else {
            return (

                <GlobalContainer ref={scrollRef}>

                    {reload && <Reload bg='rgba(0,0,0,0.7)' />}

                    <ContainerCenter visible={lineOne ? 'true' : undefined}>

                        <ContainerStart>

                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Olá, tudo bem? Sou a Jadde IA, responsável pelos agendamentos do {collaborator.name}</Text>
                            </Container>

                        </ContainerStart>

                    </ContainerCenter>

                    <ContainerCenter visible={lineOne1 ? 'true' : undefined}>

                        <ContainerStart>

                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Vamos conferir se você já tem cadastro em nosso sistema?</Text>
                            </Container>

                        </ContainerStart>

                    </ContainerCenter>

                    {/*VERIFICAÇÃO DE DADOS START*/}

                    {/*NOME*/}
                    <ContainerCenter visible={lineTwo ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Qual o seu nome e sobrenome, por favor?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineThree ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <InputScheduling value={name} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { name: e.target.value } }) }} mt='10px' placeholder="Seu nome e sobrenome" />
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFour ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <ButtonScheduling onClick={sendName} mt='10px'>Continuar</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFive ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{name}.</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>
                    {/*PHONE*/}
                    <ContainerCenter visible={lineTwo1 ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='10px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Qual o seu WhatsApp? Digite apenas os números com DDD, ok?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineThree1 ? 'true' : undefined}>

                        <ContainerStart mt='10px'>

                            <StyledTelefoneInput value={phone} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { phone: e.target.value } }) }} mask="(99) 99999-9999" maskChar=" " />;

                        </ContainerStart>

                    </ContainerCenter>

                    <ContainerCenter visible={lineFour1 ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <ButtonScheduling onClick={sendPhone} mt='10px'>Prosseguir</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFive1 ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{phone}.</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    {/*EMAIL*/}

                    <ContainerCenter visible={lineTwo2 ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Ótimo! E qual é o seu email?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineThree2 ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <InputScheduling value={email} onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { email: e.target.value } }) }} mt='10px' placeholder="Seu email" />
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFour2 ? 'true' : undefined}>
                        <ContainerStart mt='10px'>
                            <ButtonScheduling onClick={sendEmail} mt='10px'>Continuar agora</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFive2 ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{email}.</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    {/*VERIFICAÇÃO DE DADOS END*/}

                    {/*CLIENTE NÃO ENCONTRADO START*/}

                    <ContainerCenter visible={lineSix ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Prazer, {name}! Parece que você ainda não realizou nunhum agendamento. Que tal escolher um ou mais dos serviços abaixo?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>


                    {/*CLIENTE NÃO ENCONTRADO END*/}


                    {/*CLIENTE ENCONTRADO START*/}

                    <ContainerCenter visible={lineSixData ? 'true' : undefined}>
                        <ContainerEnd>
                            <ButtonMySchedulingTop onClick={() => { navigate('/myschedulings', { state: { code: codeG, email: email, phone: phone } }) }} mt='20px'>
                                Meus agendamentos
                            </ButtonMySchedulingTop>
                        </ContainerEnd>
                    </ContainerCenter>


                    <ContainerCenter visible={lineSixData ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Como vai, {name}! Estou feliz que voltou!</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={recurrenceStart ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Você é um cliente recorrente deste profissional.</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSixData ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Qual serviço você deseja agendar hoje?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    {/*CLIENTE ENCONTRADO END*/}

                    <ContainerCenter visible={lineSeven ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Para garantir que você não esqueça do seu agendamento, ative as notificações aqui embaixo, por favor.</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineEight ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling onClick={permissionNotification} mt='20px'>Ativar notificações</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineNine ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling onClick={shipPermissionNotification} lgl='#064e3b' lgr='#065f46' mt='20px'>Pular</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineTen ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>Ativar notificações</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    <ContainerCenter visible={lineTenBloqued ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>Bloquear notificações</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    <ContainerCenter visible={lineEleven ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>Pular</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSixRemake ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Ok, {name}! Vamos refazer seu agendamento.</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>



                    <ContainerCenter visible={lineTwelve ? 'true' : undefined} mt='20px'>
                        <ContainerStart>
                            <Text fcolor='#fff' fs='15px'>SELECIONE O SERVIÇO</Text>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineTwelve ? 'true' : undefined}>

                        <ContainerHorizontal mt='10px'>

                            {servicesList.map((item, index) => {

                                return (

                                    <CardServices onClick={() => { changeCheckBoxServices(item) }} key={index} checkboxservices={checkboxservices.includes(item) ? 'true' : undefined}>

                                        <ContainerFlexBetween jc='space-between'>

                                            <Text ml='10px' fcolor='#fff' >{item.name}</Text>

                                            <ButtonTransparent mr='10px' mt='10px'>
                                                {checkboxservices.includes(item)
                                                    ? <MdCheckBox size={30} color='#fff' />
                                                    : <MdCheckBoxOutlineBlank size={30} color='#fff' />
                                                }

                                            </ButtonTransparent>

                                        </ContainerFlexBetween >



                                        <ContainerFlexBetween jc='space-between' mt='10px'>
                                            <Text ml='10px' mr='10px' fcolor='#fff' >{item.value}</Text>
                                            <Text ml='10px' mr='10px' fcolor='#fff' >{item.duration} min</Text>
                                        </ContainerFlexBetween >

                                    </CardServices>
                                )
                            })}

                        </ContainerHorizontal>

                    </ContainerCenter>

                    {servicesList.length > 4 &&

                        <ContainerCenter visible={lineThirteen ? 'true' : undefined}>
                            <ContainerEnd>
                                <Text fcolor='#fff' fs='15px'>ARRASTE PARA O LADO PARA VER</Text>
                            </ContainerEnd>
                        </ContainerCenter>
                    }

                    <ContainerCenter onClick={() => { sendServices() }} visible={lineThirteenButton ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling mt='15px'>Prosseguir</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineThirteenMensage ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{listServices(checkboxservices)}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>

                    <ContainerCenter mt='50px' visible={lineThirteen ? 'true' : undefined}>
                        <ContainerStart mt='20px'>
                            <Text fcolor='#fff' fs='15px'>SELECIONE O DIA</Text>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineThirteen ? 'true' : undefined}>

                        <ContainerHorizontal mt='10px'>

                            {daysList.map((item, index) => {
                                return (

                                    <CardDays
                                        pointer={item.available ? 'pointer' : 'none'}

                                        checkboxday={checkboxday === item.date ? 'true' : undefined}

                                        key={index} onClick={() => { changeCheckBoxDay(item) }}
                                        isavailable={item.available ? 'true' : undefined}
                                    >

                                        <ContainerFlex bb={item.available ? '1px solid #fff' : '1px solid #ccc'} jc='center'>
                                            <Text td={item.available ? 'none' : 'line-through'} mt='5px' mb='5px' fw='bold' fcolor={item.available ? '#fff' : '#ccc'}>{item.short}</Text>
                                        </ContainerFlex>

                                        <ContainerFlex jc='center'>
                                            <Text mt='5px' mb='5px' fw='bold' fcolor={item.available ? '#fff' : '#ccc'}>{formatDate(item.date)}</Text>
                                        </ContainerFlex>

                                    </CardDays>

                                )
                            })}
                        </ContainerHorizontal>

                    </ContainerCenter>

                    <ContainerCenter mt='50px' visible={lineFourteen ? 'true' : undefined}>
                        <ContainerStart mt='20px'>
                            <Text fcolor='#fff' fs='15px'>SELECIONE O HORÁRIO</Text>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter mt='10px' visible={lineFourteen ? 'true' : undefined}>

                        <ContainerFlex w='1200px'>


                            {timesG.map((item, index) => {
                                return (

                                    <CardTime checkboxhour={(

                                        checkBoxHour === item.slot ? 'true' : undefined)

                                    }

                                        onClick={() => { changeCheckBoxHour(item) }} key={index} mt='10px'
                                    >
                                        <Text td={verifieldCheckBoxHour(item.available, item.slot, checkboxday) ? 'none' : 'line-through'} fcolor='#fff' fs='15px'>{item.slot}</Text>
                                    </CardTime>
                                )
                            })}

                        </ContainerFlex>

                    </ContainerCenter>

                    <ContainerCenter onClick={() => { sendHour() }} visible={lineFourteenButton ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling mt='20px'>Concluir</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineFifteen ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{schedulingResult}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>



                    <ContainerCenter visible={lineSixteen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Qual é o seu whatsapp?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSixteenData ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='50px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Seu número de whatsapp é o mesmo?</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>


                    <ContainerCenter visible={lineSixteen || lineSixteenData ? 'true' : undefined}>
                        <ContainerStart>
                            <StyledTelefoneInput
                                mt='50px'
                                mask="(99) 99999-9999"
                                placeholder="(00) 00000-0000"
                                type="text"
                                onChange={(e) => { dispatch({ type: 'SET_STATE', payload: { phone: e.target.value } }) }}
                                value={phone}
                            />
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter onClick={() => { sendPhone() }} visible={(lineSixteen ? 'true' : undefined) || (lineSixteenData ? 'true' : undefined)}>
                        <ContainerStart>
                            <ButtonScheduling mt='20px'>Enviar</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>



                    <ContainerCenter visible={lineSeventeenData ? 'true' : undefined}>
                        <ContainerEnd>
                            <Container lgl='#64748b' lgr='#475569' mt='20px'>
                                <Text fcolor='#fff' fs='15px'>{phone}</Text>
                            </Container>
                        </ContainerEnd>
                    </ContainerCenter>


                    <ContainerCenter visible={lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Perfeito, {name}!</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <Container mt='20px'>
                                <JaddeAvatar src="./images/jadde_menu.png" />
                                <Text ml='10px' fcolor='#fff' fs='15px'>Confirmando agendamento: {listServices(checkboxservices)}, com o(a) {collaborator.name} no(a) {schedulingResult}.</Text>
                            </Container>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSeventeen ? 'true' : undefined}>
                        <ContainerStart onClick={onSave}>
                            <ButtonScheduling mt='20px'>Sim, agendar agora</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>

                    <ContainerCenter visible={lineSeventeen ? 'true' : undefined}>
                        <ContainerStart>
                            <ButtonScheduling onClick={remake} lgl='#064e3b' lgr='#065f46' mt='20px'>Refazer</ButtonScheduling>
                        </ContainerStart>
                    </ContainerCenter>


                    <Spacing space='250px'></Spacing>

                </GlobalContainer>
            )
        }

    }
}