import React, { useEffect, useState } from 'react';
import AuthContext from './AuthContext';
import axios from "axios";
import { API_URL } from "../config";


const AuthProvider = ({ children }) => {
  
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [loadding, setLoadding] = useState(true);

  const [user, setUser] = useState();


  const login = () => {
    setIsAuthenticated(true);
    setLoadding(false);
  };

  const logout = () => {

    setIsAuthenticated(false);
    localStorage.removeItem('TOKEN_APP_AUTH_JADDE');
    setUser(null);
    setLoadding(false);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({
          type: 'logoutSuccess',
          data: null
      }));
    }

  };

  async function verifieldLogin()
  {
      const localData = localStorage.getItem('TOKEN_APP_AUTH_JADDE');

      if(localData)
      {
        const token = JSON.parse(localData).token

        if(token)
        {
            try {

                const response = await axios.post(`${API_URL}/verifiled-logign-web`, {token:token});

                if(response.data)
                {
                    login();
                    let person = JSON.parse(localData).person
                    setUser(person)
                    return response.data.status;
                }
    
            } catch (error) {
                logout();
            }
            return
        }
      }
      
      logout();
  }

  useEffect(()=>{
    verifieldLogin()
  },[])

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login,  logout, setUser, verifieldLogin, loadding }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
