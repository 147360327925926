import { BsArrowLeftRight, BsCurrencyDollar, BsJustify } from 'react-icons/bs';
import './styles/css/style.css';
import AuthContext from '../../context/AuthContext';
import { useContext, useEffect, useState } from 'react';
import SideBar from '../../componentes/sidebar';
import { MONTHS_AND_YAERS_STRING } from '../recurrence/constants';
import { getAllBilling, getCollaboratorBilling, getCollaborators } from './api';
import DateTime from '../../lib/DateTime';
import { monthToNumber } from './functions';



export default function Billing()
{
    const [sideBarOpen, setSideBarOpen] = useState(false);

    const {user} = useContext(AuthContext);

    const [select,  setSelect] = useState({
        date: '',
        period: '',
        collaborator: null,
        searchClient: '',
        delete: null,
        conclude: null,
        undo: null,

    })

    const [data, setData] = useState({

        clients: [],
        collaborators: [],
        recurrence: null,
        totalServicesPerDay: [],
        comparison: [],
    })

    const [render, setRender] = useState({
        recurrence: 'R$0,00',
        commissions: 'R$0,00',
        billing: 'R$0,00'
    })

    

    useEffect(()=>{
        loadCollaborators();
        setSelect((prev) => ({...prev, period: `${DateTime.getMonthName()} - ${DateTime.getCurrentYear()}`}))
        changeDate(`${DateTime.getMonthName()} - ${DateTime.getCurrentYear()}`)
    }, [])


    async function changeDate(period)
    {

        setSelect((prev) => ({
            ...prev,
            period: period
        }))

        let periodArr = period.split(' - ');

        let month = monthToNumber(periodArr[0]);
        let year = periodArr[1];

        let response;

        if(user?.type=== "admin")
        {
            response = await getAllBilling(user?.id, month, year);
        }else{
            response = await getCollaboratorBilling(user?.id, month, year);
        }

        setRender((prev) => ({
            ...prev,
            commissions: response.commissions,
            billing: response.billing,
            recurrence: response.totalRecurrenceBilling
        }))

        setData((prev)=>({
            ...prev,
            totalServicesPerDay: response.totalServicesPerDay,
            comparison: response.comparison,
        }))

        console.log(response);


    }

    async function loadCollaborators()
    {
        const result = await getCollaborators(user?.id);

        console.log(result)

        setData((prev) => ({...prev, collaborators: result}));
    }

    async function changeCollaborator(item)
    {

        let periodArr = select.period.split(' - ');

        let month = monthToNumber(periodArr[0]);
        let year = periodArr[1];

        const response = await getCollaboratorBilling(item, month, year);

        setRender((prev) => ({
            ...prev,
            commissions: response.commissions,
            billing: response.billing,
            recurrence: response.totalRecurrenceBilling
        }))

        setData((prev)=>({
            ...prev,
            totalServicesPerDay: response.totalServicesPerDay,
            comparison: response.comparison,
        }))
    }

    return(

        <div className="container-billing">

            <SideBar
                sideBarOpen={sideBarOpen} 
                setSideBarOpen={setSideBarOpen}
            />

            <div className='header'>
                <button onClick={()=>{setSideBarOpen(true)}} className="btn-transparent ">
                    <BsJustify size={25} color="#fff" />
                </button>

                <h3>Faturamento</h3>

                <div className="btn-transparent">
                    <BsCurrencyDollar size={25} color="#fff" />
                </div>
            </div>

            <div className='content'>

                <div className='w-full'>
                    <p>Período</p>
                </div>

                <div className='form-group'>

                    <select value={select.period} onChange={(e)=>{changeDate(e.target.value)}}>

                        {MONTHS_AND_YAERS_STRING.map((item, index) =>{
                            return(
                                <option key={index}>
                                    {item.month} - {item.year}
                                </option>
                            )
                        })}

                    </select>

                </div>

                {user?.type=== "admin" &&
                <>
                    <div className='w-full mt-10'>
                        <p>Colaboradores</p>
                    </div>

                    <div className='form-group'>

                        <select onChange={(e)=>{changeCollaborator(e.target.value)}}>

                            <option>Todos</option>

                            {data.collaborators.map((item, index) =>{
                                return(
                                    <option value={item.id} key={index}>{item.name}</option>
                                )
                            })}

                        </select>

                    </div>               

                </>
            
                }

                <div className='w-full mt-20'>
                    <h1>Faturamento: {render.billing}</h1>
                </div>
                <div className='w-full mt-20'>
                    <h1>Recorrência: {render.recurrence}</h1>
                </div>
                <div className='w-full mt-20'>
                    <h1>Comissões: {render.commissions}</h1>
                </div>

                {data.totalServicesPerDay.length > 0 &&
                <>
                    <div className='w-full mt-20'>
                        <p>Serviços atendidos</p>
                    </div>

                    <div className='carroussel'>

                        {data.totalServicesPerDay.map((item, index) =>{
                            return(

                                <div key={index} className='item-bar'>

                                    <div style={{height: `${item.percentual}`}} className='item-bar-fill'></div>
                                    <div className='item-day'><span>{item.day}</span></div>
                                    <div className='item-count'><span>{item.servicesCount}</span></div>
                                    <div className='item-percentage'><span>{item.percentual}</span></div>
            
                                </div>
                            )
                        })}
                        
                    </div>

                </>
                }

                <div className='w-full mt-20'>
                    <p>Comparativo mensal</p>
                </div>

                <div className='carroussel'>

                    {data.comparison.map((item, index) =>{
                        return(
                            <div key={index} className='item-bar'>

                                <div style={{height: `${item.billingPercentage}`}} className='item-bar-fill'></div>
                                <div className='item-money'><span>{item.billing}</span></div>
                                <div className='item-month'><span>{item.month}</span></div>
        
                            </div>
                        )
                    })}
                    


                </div>

                <div className='w-full mt-20'>
                    <p>Comissões</p>
                </div>

                <div className='carroussel'>
                    
                    {data.comparison.map((item, index) =>{
                        return(
                            <div key={index} className='item-bar'>

                                <div style={{height: `${item.commissionsPercentage}`}} className='item-bar-fill'></div>
                                <div className='item-money'><span>{item.commissions}</span></div>
                                <div className='item-month'><span>{item.month}</span></div>
        
                            </div>
                        )
                    })}

                </div>


                <div className="space_mobile"></div>

            </div>

        </div>
    )
}