import { useState } from "react";
import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { deleteCollaborator, deleteService } from "../../functions";
import { SpinnerCircular } from "spinners-react";


export default function ModalDeleteCollaborator({setModal, user, setCollaboratorsList, setCollaboratorDelete, collaboratorDelete})
{

    const [sppiner, setSpinner] = useState(false);

    function cancel()
    {
        setCollaboratorDelete(null);

        setModal((modal)=>({
            ...modal,
            deleteCollaborator: false
        }))
    }

    async function destroy()
    {

        setSpinner(true);

        const collaborators = await deleteCollaborator(collaboratorDelete.id);

        setCollaboratorsList(collaborators.data);

        setSpinner("success");

        setCollaboratorDelete(null);
        setModal((modal)=>({
            ...modal,
            deleteCollaborator: false
        }))

    }



    return(
        
        <div className="modal-delete-container ">

            <div className="modal-content">

                <div className="header">
                        <button onClick={()=>{setModal((modal)=>({...modal, deleteCollaborator:false}))}} className="btn-transparent">
                            <BsThreeDots size={30} color="#000"/>
                        </button>
                </div>

                <div className="main">

                    <h3> Deletar colaborador:</h3>
                    <span>{collaboratorDelete.name}</span>

                </div>

                <div className="footer">
                    <button onClick={()=>{cancel()}} className="cancel">Cancelar</button>
                    <button onClick={()=>{destroy()}} className="delete">
                        {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro exluído com sucesso! </> :  "Deletar"}
                    </button>
                </div>

            </div>

        </div>
    )
}