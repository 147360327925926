import { useContext, useEffect, useState } from "react";
import { BsApp, BsCheckSquare } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { register, verifieldName } from "./function";
import { SpinnerCircular } from "spinners-react";
import AuthContext from "../../context/AuthContext";
import './styles/css/styles.css';
import InputMask from "../../lib/InputMask";
import axios from "axios";

export default function Register()
{
    const {login, setUser} = useContext(AuthContext);

    const [texts, setTexts] = useState({
        header: '',
        main_line_1: '',
        main_line_2: '',
        main_line_3: '',
        main_line_4: '',
    })

    const [check, setCheck] = useState({
        keepLoggedIn: false
    })

    const [form, setForm] = useState({
        name:'',
        email:'',
        phone:'',
        password:'',
        repassword:''
    })

    const [errors, setErrors] = useState({
        name:'',
        email:'',
        phone:'',
        password:'',
        repassword:'',
        keepLoggedIn:''
    })

    const [sppiner, setSpinner] = useState(false)

    const [modal, setModal] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        handleText();
     },[])

     async function handleText()
     {
        let response;
        //https://api.jadde.com.br/api/text-page?name=register
        response = await axios.get('https://api.jadde.com.br/api/text-page?name=register');

        if(response && response.data && response.data.content)
        {
            setTexts((prev)=>({
                ...prev,
                header: response.data.content.header,
                main_line_1: response.data.content.main_line_1,
                main_line_2: response.data.content.main_line_2,
            }))
        }
     }

    async function handleRegister()
    {
        setSpinner(true);

        if(form.name === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'Nome não pode ser vazio',
                phone:'',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(verifieldName(form.name) === false)
        {
            setErrors((errors)=>({
                ...errors, 
                name:'Informe seu nome completo',
                phone:'',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.email === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                password:'',
                repassword:'',
                email: 'Email não pode ser vazio!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(!form.email.includes('@'))
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                password:'',
                repassword:'',
                email: 'Email com formato inválido!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.phone === '')
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'Número do whatsapp não pode ser vazio!',
                password:'',
                repassword:'',
                email: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password === '')
        {
            setErrors((errors)=>({
                ...errors, 
                email: '',
                name:'',
                phone:'',
                repassword:'',
                password: 'Senha não pode ser vazio!',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password.length < 8)
        {
            setErrors((errors)=>({
                ...errors, 
                name:'',
                phone:'',
                email: '',
                password: 'Senha não pode ser menor que 8 caracteres!',
                repassword: '',
                keepLoggedIn:''
            }))
            setSpinner(false);
            return
        }

        if(form.password !== form.repassword)
        {
            setErrors((errors)=>({
                ...errors,
                name:'',
                phone:'',
                email: '',
                password: 'Senha não são iguais',
                repassword: 'Senhas não são iguais',
                keepLoggedIn:''
            }));
            setSpinner(false);
            return
        }

        if(check.keepLoggedIn === false)
        {
            setErrors((errors)=>({
                ...errors,
                name:'',
                phone:'',
                email: '',
                password: '',
                repassword: '',
                keepLoggedIn:'Você tem que aceitar os termos e política de privacidade.'
            }));
            setSpinner(false);
            return
        }

        const response = await register(form)
        .then(response => {
            setSpinner(false);
            if (Array.isArray(response)) {
                alert(response);
                return;
            }
            localStorage.setItem('TOKEN_APP_AUTH_JADDE', JSON.stringify(response));
            setUser(response.person);
            setTimeout(() => {
                login(true);
                navigate('/')
            }, 1000);
        
        })
        .catch(error => {
            // Tratamento de erro
            if (Array.isArray(error) && error.includes('email já existe no banco de dados')) {
                alert(error);
            } else {
                alert('Ocorreu um erro ao processar sua solicitação.');
            }
        });
    }


    return(

        <div className="register-container">
            <div className="header">
                <div>
                    <div dangerouslySetInnerHTML={{ __html: texts.header}} />
                </div>
                <img src="images/jadde_presentation_two.png"/>
            </div>

            <div className="container-form-auth">

                <div className="container-center">
                    <input value={form.name} onChange={(e)=>{setForm((form)=>({...form, name:e.target.value}))}} type="text" className="input-form mt-50" placeholder="Digite seu nome completo"/>
                </div>
                <div className="alert-red">
                    <span>{errors.name}</span>  
                </div>
                <div className="container-center">
                    <input value={form.email} onChange={(e)=>{setForm((form)=>({...form, email:e.target.value}))}} type="email" className="input-form mt-20" placeholder="Digite seu email"/>
                </div>
                <div className="alert-red">
                    <span>{errors.email}</span>  
                </div>
                <div className="container-center">
                    <input value={form.phone} onChange={(e)=>{setForm((form)=>({...form, phone: InputMask.phone(e.target.value)}))}} type="text" className="input-form mt-20" placeholder="Digite o número do seu whatsapp"/>
                </div>
                <div className="alert-red">
                    <span>{errors.phone}</span>  
                </div>
                <div className="container-center">
                    <input value={form.password} onChange={(e)=>{setForm((form)=>({...form, password:e.target.value}))}} type="password" className="input-form mt-20" placeholder="Digite sua senha com 8 dígitos"/>
                </div>
                <div className="alert-red">
                    <span>{errors.password}</span>  
                </div>
                <div className="container-center">
                    <input value={form.repassword}  onChange={(e)=>{setForm((form)=>({...form, repassword:e.target.value}))}} type="password" className="input-form mt-20" placeholder="Repita sua senha com 8 dígitos"/>
                </div>
                <div className="alert-red">
                    <span>{errors.repassword}</span>  
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{setModal(true)}} className="btn-transparent btn-form-auth">
                         {check.keepLoggedIn === false ? <BsApp /> : <BsCheckSquare />}
                         <span>Aceitar termos e política de privacidade</span>
                    </button>
                </div>

                <div className="alert-red">
                    <span>{errors.keepLoggedIn}</span>  
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{handleRegister()}} className="btn-form">
                        {sppiner === true ? <SpinnerCircular size={20}/> : 'Registrar'}
                    </button>
                </div>

                <div className="container-center mt-20">
                    <button onClick={()=>{navigate('/login')}} className="btn-form-outline">
                         Entrar
                    </button>
                </div>

            </div>

            <div className={modal === true ? "modal-terms" : "hidden"}>

                <div className="modal-terms-header">
                    <div dangerouslySetInnerHTML={{ __html: texts.main_line_1}} />
                </div>

                <div className="modal-terms-content">

                    <h1 class="c11" id="h.gjdgxs"><span class="c4 c9">Pol&iacute;tica de Privacidade Jadde IA</span></h1>
                    <p class="c2"><span>Na </span><span class="c4">Jadde IA </span><span>, privacidade e seguran&ccedil;a s&atilde;o prioridades e nos comprometemos com a transpar&ecirc;ncia do tratamento de dados pessoais dos nossos usu&aacute;rios/clientes. Por isso, essa presente Pol&iacute;tica de Privacidade estabelece como &eacute; feita a coleta, uso e transfer&ecirc;ncia de informa&ccedil;&otilde;es de clientes ou outras pessoas que acessam ou usam nosso site. Ao utilizar nossos servi&ccedil;os, voc&ecirc; entende que coletaremos e usaremos suas informa&ccedil;&otilde;es pessoais nas formas descritas nesta Pol&iacute;tica, sob as normas de Prote&ccedil;&atilde;o de Dados (LGPD, Lei Federal 13.709/2018), das disposi&ccedil;&otilde;es consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jur&iacute;dico brasileiro aplic&aacute;veis. Dessa forma, a Mazza Digital doravante denominada simplesmente como &ldquo;</span><span class="c4">JADDE IA</span><span class="c1">&rdquo;, inscrita no CNPJ sob o n&ordm; 45.618.509/0001-30 no papel de Controladora de Dados, obriga-se ao disposto na presente Pol&iacute;tica de Privacidade.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">1. Quais dados coletamos sobre voc&ecirc; e para qual finalidade?</span></p>
                    <p class="c2"><span class="c1">Nosso site coleta e utiliza alguns dados pessoais seus, de forma a viabilizar a presta&ccedil;&atilde;o de servi&ccedil;os e aprimorar a experi&ecirc;ncia de uso.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c4 c8">1.1. Dados pessoais fornecidos pelo titular</span></p>
                    <p class="c0"><span class="c8 c4"></span></p>
                    <p class="c2"><span class="c1">- Nome completo e Data de nascimento</span></p>
                    <p class="c2"><span class="c1">- N&uacute;mero e imagem da Carteira de Identidade (RG)</span></p>
                    <p class="c2"><span class="c1">- N&uacute;mero e imagem do Cadastro de Pessoas F&iacute;sicas (CPF)</span></p>
                    <p class="c2"><span class="c1">- N&uacute;mero e imagem da Carteira Nacional de Habilita&ccedil;&atilde;o (CNH)</span></p>
                    <p class="c2"><span class="c1">- Fotografia 3&times;4 e Estado civil</span></p>
                    <p class="c2"><span class="c1">- N&iacute;vel de instru&ccedil;&atilde;o ou escolaridade</span></p>
                    <p class="c2"><span class="c1">- Endere&ccedil;o completo</span></p>
                    <p class="c2"><span class="c1">- N&uacute;meros de telefone, WhatsApp e endere&ccedil;os de e-mail</span></p>
                    <p class="c2"><span class="c1">- Banco, ag&ecirc;ncia e n&uacute;mero de contas banc&aacute;rias</span></p>
                    <p class="c2"><span class="c1">- Bandeira, n&uacute;mero, validade e c&oacute;digo de cart&otilde;es de cr&eacute;dito</span></p>
                    <p class="c2"><span class="c1">- Nome de usu&aacute;rio e senha espec&iacute;ficos para uso dos servi&ccedil;os da Controladora</span></p>
                    <p class="c2"><span class="c1">- Comunica&ccedil;&atilde;o, verbal e escrita, mantida entre o Titular e a Controladora</span></p>
                    <p class="c2"><span class="c1">- Base de contatos no celular, caso expressamente deseje a importa&ccedil;&atilde;o dessas informa&ccedil;&otilde;es em nosso sistema</span></p>
                    <p class="c2"><span class="c1">- Demais dados pertinentes ao devido uso dos servi&ccedil;os</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c8 c4">1.2. Dados pessoais coletados automaticamente</span></p>
                    <p class="c2"><span class="c1">Dados de navega&ccedil;&atilde;o nas p&aacute;ginas do sistema</span></p>
                    <p class="c2"><span class="c1">Estat&iacute;sticas de uso</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">2. Como coletamos os seus dados?</span></p>
                    <p class="c2"><span class="c1">Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma: Cookies e Formul&aacute;rios de cadastros de informa&ccedil;&otilde;es</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c8 c4">2.1. Consentimento</span></p>
                    <p class="c2"><span>&Eacute; a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento &eacute; a manifesta&ccedil;&atilde;o livre, informada e inequ&iacute;voca pela qual voc&ecirc; autoriza a </span><span class="c4">JADDE IA </span><span class="c1">a tratar seus dados. Assim, em conson&acirc;ncia com a Lei Geral de Prote&ccedil;&atilde;o de Dados, seus dados s&oacute; ser&atilde;o coletados, tratados e armazenados mediante pr&eacute;vio e expresso consentimento.</span></p>
                    <p class="c2"><span class="c1">&zwj;</span></p>
                    <p class="c2"><span>O seu consentimento ser&aacute; obtido de forma espec&iacute;fica para cada finalidade acima descrita, evidenciando o compromisso de transpar&ecirc;ncia e boa-f&eacute; da </span><span class="c4">JADDE IA </span><span class="c1">para com seus usu&aacute;rios/clientes, seguindo as regula&ccedil;&otilde;es legislativas pertinentes.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>Ao utilizar os servi&ccedil;os da </span><span class="c4">JADDE IA </span><span class="c1">e fornecer seus dados pessoais, voc&ecirc; est&aacute; ciente e consentindo com as disposi&ccedil;&otilde;es desta Pol&iacute;tica de Privacidade, al&eacute;m de conhecer seus direitos e como exerc&ecirc;-los.</span></p>
                    <p class="c2"><span class="c1">&zwj;</span></p>
                    <p class="c2"><span class="c1">A qualquer tempo e sem nenhum custo, voc&ecirc; poder&aacute; revogar seu consentimento. &Eacute; importante destacar que a revoga&ccedil;&atilde;o do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do site que dependa da opera&ccedil;&atilde;o. Tais consequ&ecirc;ncias ser&atilde;o informadas previamente.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">3. Quais s&atilde;o os seus direitos?</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>A </span><span class="c4">JADDE IA </span><span class="c1">assegura a seus usu&aacute;rios/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Prote&ccedil;&atilde;o de Dados.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Dessa forma, voc&ecirc; pode, de maneira gratuita e a qualquer tempo:</span></p>
                    <p class="c2"><span>- </span><span class="c4">Confirmar a exist&ecirc;ncia de tratamento de dados,</span><span class="c1">&nbsp;de maneira simplificada ou em formato claro e completo.</span></p>
                    <p class="c2"><span>- </span><span class="c4">Acessar seus dados,</span><span class="c1">&nbsp;podendo solicit&aacute;-los em uma c&oacute;pia leg&iacute;vel sob forma impressa ou por meio eletr&ocirc;nico, seguro e id&ocirc;neo.</span></p>
                    <p class="c2"><span>- </span><span class="c4">Corrigir seus dados,</span><span class="c1">&nbsp;ao solicitar a edi&ccedil;&atilde;o, corre&ccedil;&atilde;o ou atualiza&ccedil;&atilde;o destes.</span></p>
                    <p class="c2"><span>- </span><span class="c8 c4">Limitar seus dados quando desnecess&aacute;rios, excessivos ou tratados em desconformidade com a legisla&ccedil;&atilde;o atrav&eacute;s da anonimiza&ccedil;&atilde;o, bloqueio ou elimina&ccedil;&atilde;o.</span></p>
                    <p class="c2"><span class="c4">- Solicitar a portabilidade de seus dados</span><span>, atrav&eacute;s de um relat&oacute;rio de dados cadastrais que a </span><span class="c4">JADDE IA </span><span class="c1">trata a seu respeito.</span></p>
                    <p class="c2"><span>- </span><span class="c4">Eliminar seus dados</span><span class="c1">&nbsp;tratados a partir de seu consentimento, exceto nos casos previstos em lei.</span></p>
                    <p class="c2"><span>- </span><span class="c4">Revogar seu consentimento</span><span class="c1">, desautorizando o tratamento de seus dados.</span></p>
                    <p class="c2"><span>- </span><span class="c4">Informar-se sobre a possibilidade de n&atilde;o fornecer seu consentimento</span><span class="c1">&nbsp;e sobre as consequ&ecirc;ncias da negativa.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">4. Como voc&ecirc; pode exercer seus direitos de titular?</span></p>
                    <p class="c2"><span>Para exercer seus direitos de titular, voc&ecirc; deve entrar em contato com a </span><span class="c4">JADDE IA </span><span class="c1">atrav&eacute;s dos seguintes meios dispon&iacute;veis: contato@jadde.com.br</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Correio: AVENIDA BRIG FARIA LIMA, 2369 - CONJ 1102 - JARDIM PAULISTANO</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">De forma a garantir a sua correta identifica&ccedil;&atilde;o como titular dos dados pessoais objeto da solicita&ccedil;&atilde;o, &eacute; poss&iacute;vel que solicitemos documentos ou demais comprova&ccedil;&otilde;es que possam comprovar sua identidade. Nessa hip&oacute;tese, voc&ecirc; ser&aacute; informado previamente.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">5. Como e por quanto tempo seus dados ser&atilde;o armazenados?</span></p>
                    <p class="c2"><span>Seus dados pessoais coletados pela </span><span class="c4">JADDE IA </span><span class="c1">ser&atilde;o utilizados e armazenados durante o tempo necess&aacute;rio para a presta&ccedil;&atilde;o do servi&ccedil;o ou para que as finalidades elencadas na presente Pol&iacute;tica de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>De modo geral, seus dados ser&atilde;o mantidos enquanto a rela&ccedil;&atilde;o contratual entre voc&ecirc; e a </span><span class="c4">JADDE IA </span><span class="c1">perdurar.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Findado o per&iacute;odo de armazenamento dos dados pessoais, estes ser&atilde;o exclu&iacute;dos de nossas bases de dados ou anonimizados, ressalvadas as hip&oacute;teses legalmente previstas no artigo 16 lei geral de prote&ccedil;&atilde;o de dados, a saber:</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">I &ndash; cumprimento de obriga&ccedil;&atilde;o legal ou regulat&oacute;ria pelo controlador;</span></p>
                    <p class="c2"><span class="c1">II &ndash; estudo por &oacute;rg&atilde;o de pesquisa, garantida, sempre que poss&iacute;vel, a anonimiza&ccedil;&atilde;o dos dados pessoais;</span></p>
                    <p class="c2"><span class="c1">III &ndash; transfer&ecirc;ncia a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; ou</span></p>
                    <p class="c2"><span class="c1">IV &ndash; uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>Isto &eacute;, informa&ccedil;&otilde;es pessoais sobre voc&ecirc; que sejam imprescind&iacute;veis para o cumprimento de determina&ccedil;&otilde;es legais, judiciais e administrativas e/ou para o exerc&iacute;cio do direito de defesa em processos judiciais e administrativos ser&atilde;o mantidas, a despeito da exclus&atilde;o dos demais dados. O armazenamento de dados coletados pela </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;reflete o nosso compromisso com a seguran&ccedil;a e privacidade dos seus dados. Empregamos medidas e solu&ccedil;&otilde;es t&eacute;cnicas de prote&ccedil;&atilde;o aptas a garantir a confidencialidade, integridade e inviolabilidade dos seus dados. Al&eacute;m disso, tamb&eacute;m contamos com medidas de seguran&ccedil;a apropriadas aos riscos e com controle de acesso &agrave;s informa&ccedil;&otilde;es armazenadas.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">6. O que fazemos para manter seus dados seguros?</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Para mantermos suas informa&ccedil;&otilde;es pessoais seguras, usamos ferramentas f&iacute;sicas, eletr&ocirc;nicas e gerenciais orientadas para a prote&ccedil;&atilde;o da sua privacidade. Aplicamos essas ferramentas levando em considera&ccedil;&atilde;o a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais viola&ccedil;&otilde;es gerariam para os direitos e liberdades do titular dos dados coletados e tratados.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Entre as medidas que adotamos, destacamos as seguintes:</span></p>
                    <p class="c2"><span class="c1">- Apenas pessoas autorizadas t&ecirc;m acesso a seus dados pessoais</span></p>
                    <p class="c2"><span class="c1">- O acesso a seus dados pessoais &eacute; feito somente ap&oacute;s o compromisso de confidencialidade</span></p>
                    <p class="c2"><span class="c1">- Seus dados pessoais s&atilde;o armazenados em ambiente seguro e id&ocirc;neo.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>A </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;se compromete a adotar as melhores posturas para evitar incidentes de seguran&ccedil;a. Contudo, &eacute; necess&aacute;rio destacar que nenhuma p&aacute;gina virtual &eacute; inteiramente segura e livre de riscos. &Eacute; poss&iacute;vel que, apesar de todos os nossos protocolos de seguran&ccedil;a, problemas de culpa exclusivamente de terceiros ocorram, como ataques cibern&eacute;ticos de hackers, ou tamb&eacute;m em decorr&ecirc;ncia da neglig&ecirc;ncia ou imprud&ecirc;ncia do pr&oacute;prio usu&aacute;rio/cliente.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Em caso de incidentes de seguran&ccedil;a que possa gerar risco ou dano relevante para voc&ecirc; ou qualquer um de nossos usu&aacute;rios/clientes, comunicaremos aos afetados e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados sobre o ocorrido, em conson&acirc;ncia com as disposi&ccedil;&otilde;es da Lei Geral de Prote&ccedil;&atilde;o de Dados.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">7. Com quem seus dados podem ser compartilhados?</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>Tendo em vista a preserva&ccedil;&atilde;o de sua privacidade, a </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;n&atilde;o compartilhar&aacute; seus dados pessoais com nenhum terceiro n&atilde;o autorizado.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Seus dados poder&atilde;o ser compartilhados com nossos parceiros comerciais: Estes recebem seus dados apenas na medida do necess&aacute;rio para a presta&ccedil;&atilde;o dos servi&ccedil;os contratados e nossos contratos s&atilde;o orientados pelas normas de prote&ccedil;&atilde;o de dados do ordenamento jur&iacute;dico brasileiro.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Todavia, nossos parceiros t&ecirc;m suas pr&oacute;prias Pol&iacute;ticas de Privacidade, que podem divergir desta.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Al&eacute;m disso, tamb&eacute;m existem outras hip&oacute;teses em que seus dados poder&atilde;o ser compartilhados, que s&atilde;o:</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">I &ndash; Determina&ccedil;&atilde;o legal, requerimento, requisi&ccedil;&atilde;o ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.</span></p>
                    <p class="c2"><span class="c1">II &ndash; Caso de movimenta&ccedil;&otilde;es societ&aacute;rias, como fus&atilde;o, aquisi&ccedil;&atilde;o e incorpora&ccedil;&atilde;o, de forma autom&aacute;tica</span></p>
                    <p class="c2"><span>III &ndash; Prote&ccedil;&atilde;o dos direitos da </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;em qualquer tipo de conflito, inclusive os de teor judicial.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c8 c4">7.1. Transfer&ecirc;ncia internacional de dados</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou ou possuir instala&ccedil;&otilde;es localizadas em pa&iacute;ses estrangeiros. Nessas condi&ccedil;&otilde;es, de toda forma, seus dados pessoais estar&atilde;o sujeitos &agrave; Lei Geral de Prote&ccedil;&atilde;o de Dados e &agrave;s demais legisla&ccedil;&otilde;es brasileiras de prote&ccedil;&atilde;o de dados</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Nesse sentido, a JADDE IA se compromete a sempre adotar eficientes padr&otilde;es de seguran&ccedil;a cibern&eacute;tica e de prote&ccedil;&atilde;o de dados, nos melhores esfor&ccedil;os de garantir e cumprir as exig&ecirc;ncias legislativas. Ao concordar com essa Pol&iacute;tica de Privacidade, voc&ecirc; concorda com esse compartilhamento, que se dar&aacute; conforme as finalidades descritas no presente instrumento.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">8. Cookies ou dados de navega&ccedil;&atilde;o</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>A </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;faz uso de Cookies, que s&atilde;o arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que cont&eacute;m informa&ccedil;&otilde;es relacionadas &agrave; navega&ccedil;&atilde;o do site. Em suma, os Cookies s&atilde;o utilizados para aprimorar a experi&ecirc;ncia de uso.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Ao acessar nosso site e consentir com o uso de Cookies, voc&ecirc; manifesta conhecer e aceitar a utiliza&ccedil;&atilde;o de um sistema de coleta de dados de navega&ccedil;&atilde;o com o uso de Cookies em seu dispositivo.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Voc&ecirc; pode, a qualquer tempo e sem nenhum custo, alterar as permiss&otilde;es, bloquear ou recusar os Cookies.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Todavia, a revoga&ccedil;&atilde;o do consentimento de determinados Cookies pode inviabilizar o funcionamento correto de alguns recursos da plataforma.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Para gerenciar os cookies do seu navegador, basta faz&ecirc;-lo diretamente nas configura&ccedil;&otilde;es do navegador, na &aacute;rea de gest&atilde;o de Cookies. Voc&ecirc; pode acessar tutoriais sobre o tema diretamente nos links abaixo:</span></p>
                    <p class="c2"><span class="c1">&zwj;</span></p>
                    <p class="c2"><span>- Se voc&ecirc; usa o Internet Explorer:</span><span><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158710140&amp;usg=AOvVaw2ESoVpBBX18lg-0jOML8Ew">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158710438&amp;usg=AOvVaw3VIorAmiyTyzbw3ed-_G_-">siga o link</a></span></p>
                    <p class="c2"><span class="c7"><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158710628&amp;usg=AOvVaw12YJJ_0tS-pu-6iuXskugJ">-</a></span><span class="c7">&nbsp;</span><span>Se voc&ecirc; usa o Firefox:</span><span><a class="c6" href="https://www.google.com/url?q=https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s&amp;sa=D&amp;source=editors&amp;ust=1719599158710866&amp;usg=AOvVaw0ctrTIKsWfpJHaRJc67F4k">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s&amp;sa=D&amp;source=editors&amp;ust=1719599158711030&amp;usg=AOvVaw1HbxOjVVIs6n86f50aeT41">siga o link</a></span></p>
                    <p class="c2"><span class="c7"><a class="c6" href="https://www.google.com/url?q=https://support.mozilla.org/pt-BR/kb/gerencie-configuracoes-de-armazenamento-local-de-s&amp;sa=D&amp;source=editors&amp;ust=1719599158711227&amp;usg=AOvVaw0TDswzCbc-CLiofVcP2RTr">-</a></span><span class="c7">&nbsp;</span><span>Se voc&ecirc; usa o Safari:</span><span><a class="c6" href="https://www.google.com/url?q=https://support.apple.com/pt-br/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1719599158711383&amp;usg=AOvVaw36oZMzN4Tb0GIdEAxQb-O-">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://support.apple.com/pt-br/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1719599158711568&amp;usg=AOvVaw2c0dAUUeFHmlrO7fir6ixJ">siga o link</a></span></p>
                    <p class="c2"><span class="c7"><a class="c6" href="https://www.google.com/url?q=https://support.apple.com/pt-br/guide/safari/sfri11471/mac&amp;sa=D&amp;source=editors&amp;ust=1719599158711715&amp;usg=AOvVaw1fAF4F4MX8iKEWVKilUxzr">-</a></span><span class="c7">&nbsp;</span><span>Se voc&ecirc; usa o Google Chrome:</span><span><a class="c6" href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?co%3DGENIE.Platform%253DDesktop%26oco%3D1%26hl%3Dpt-BR&amp;sa=D&amp;source=editors&amp;ust=1719599158711902&amp;usg=AOvVaw0IsvdT7bF8qYFipILEw3mW">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?co%3DGENIE.Platform%253DDesktop%26oco%3D1%26hl%3Dpt-BR&amp;sa=D&amp;source=editors&amp;ust=1719599158712065&amp;usg=AOvVaw12QYmErtJxrhnyxO3RnUb6">siga o link</a></span></p>
                    <p class="c2"><span class="c7"><a class="c6" href="https://www.google.com/url?q=https://support.google.com/chrome/answer/95647?co%3DGENIE.Platform%253DDesktop%26oco%3D1%26hl%3Dpt-BR&amp;sa=D&amp;source=editors&amp;ust=1719599158712230&amp;usg=AOvVaw3jY8UKBj4kBZL17pRaWSgW">-</a></span><span class="c7">&nbsp;</span><span>Se voc&ecirc; usa o Microsoft Edge:</span><span><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158712400&amp;usg=AOvVaw1B6si9adkPiRA_8h8SQ9UR">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158712551&amp;usg=AOvVaw1uEImt9MY1mCPx6HixL1jE">siga o link</a></span></p>
                    <p class="c2"><span class="c7"><a class="c6" href="https://www.google.com/url?q=https://support.microsoft.com/pt-br/help/4027947/microsoft-edge-delete-cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158712708&amp;usg=AOvVaw2OQy0Lpxlq-2VF-LBrrBqk">-</a></span><span class="c7">&nbsp;</span><span>Se voc&ecirc; usa o Opera:</span><span><a class="c6" href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/%23cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158712873&amp;usg=AOvVaw2QRdmMIjvTXfLCKRwHAQFe">&nbsp;</a></span><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/%23cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158713007&amp;usg=AOvVaw0nl6ItdklstPLEizzND1FD">siga o link</a></span></p>
                    <p class="c0"><span class="c5"><a class="c6" href="https://www.google.com/url?q=https://help.opera.com/en/latest/web-preferences/%23cookies&amp;sa=D&amp;source=editors&amp;ust=1719599158713149&amp;usg=AOvVaw3HPXpThQ5K6qTgbm6xkTJ3"></a></span></p>
                    <p class="c2"><span class="c1">9. Altera&ccedil;&atilde;o desta Pol&iacute;tica de Privacidade</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">A atual vers&atilde;o da Pol&iacute;tica de Privacidade foi formulada e atualizada pela &uacute;ltima vez em: 05/04/2024.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Reservamos o direito de modificar essa Pol&iacute;tica de Privacidade a qualquer tempo, principalmente em fun&ccedil;&atilde;o da adequa&ccedil;&atilde;o a eventuais altera&ccedil;&otilde;es feitas em nosso site ou em &acirc;mbito legislativo. Recomendamos que voc&ecirc; a revise com frequ&ecirc;ncia.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Eventuais altera&ccedil;&otilde;es entrar&atilde;o em vigor a partir de sua publica&ccedil;&atilde;o em nosso site e sempre lhe notificaremos acerca das mudan&ccedil;as ocorridas.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Ao utilizar nossos servi&ccedil;os e fornecer seus dados pessoais ap&oacute;s tais modifica&ccedil;&otilde;es, voc&ecirc; &agrave;s consente.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">10. Responsabilidade</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>A </span><span class="c4">JADDE IA</span><span class="c1">&nbsp; prev&ecirc; a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Prote&ccedil;&atilde;o de Dados. Nos comprometemos em manter esta Pol&iacute;tica de Privacidade atualizada, observando suas disposi&ccedil;&otilde;es e zelando por seu cumprimento.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Al&eacute;m disso, tamb&eacute;m assumimos o compromisso de buscar condi&ccedil;&otilde;es t&eacute;cnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>Caso a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados exija a ado&ccedil;&atilde;o de provid&ecirc;ncias em rela&ccedil;&atilde;o ao tratamento de dados realizado pela </span><span class="c4">JADDE IA</span><span class="c1">, comprometemo-nos a segui-las.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c8 c4">10.1 Isen&ccedil;&atilde;o de responsabilidade</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>Conforme mencionado no T&oacute;pico 6, embora adotemos elevados padr&otilde;es de seguran&ccedil;a a fim de evitar incidentes, n&atilde;o h&aacute; nenhuma p&aacute;gina virtual ou aplicativo inteiramente livre de riscos. Nesse sentido, a </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;n&atilde;o se responsabiliza por:</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">I &ndash; Quaisquer consequ&ecirc;ncias decorrentes da neglig&ecirc;ncia, imprud&ecirc;ncia ou imper&iacute;cia dos usu&aacute;rios em rela&ccedil;&atilde;o a seus dados individuais. Garantimos e nos responsabilizamos apenas pela seguran&ccedil;a dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento. Destacamos que a responsabilidade em rela&ccedil;&atilde;o &agrave; confidencialidade dos dados de acesso &eacute; do usu&aacute;rio.</span></p>
                    <p class="c2"><span>II &ndash; A&ccedil;&otilde;es maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da </span><span class="c4">JADDE IA</span><span class="c1">.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Destacamos que em caso de incidentes de seguran&ccedil;a que possam gerar risco ou dano relevante para voc&ecirc; ou qualquer um de nossos usu&aacute;rios/clientes, comunicaremos aos afetados e a Autoridade Nacional de Prote&ccedil;&atilde;o de Dados sobre o ocorrido e cumpriremos as provid&ecirc;ncias necess&aacute;rias.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>III &ndash; Inveracidade das informa&ccedil;&otilde;es inseridas pelo usu&aacute;rio/cliente nos registros necess&aacute;rios para a utiliza&ccedil;&atilde;o dos servi&ccedil;os da </span><span class="c4">JADDE IA</span><span class="c1">; quaisquer consequ&ecirc;ncias decorrentes de informa&ccedil;&otilde;es falsas ou inseridas de m&aacute;-f&eacute; s&atilde;o de inteiramente responsabilidade do usu&aacute;rio/cliente.</span></p>
                    <p class="c2"><span class="c1">&zwj;</span></p>
                    <p class="c2"><span class="c1">11. Encarregado de Prote&ccedil;&atilde;o de Dados</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span>A </span><span class="c4">JADDE IA</span><span class="c1">&nbsp;disponibiliza os seguintes meios para que voc&ecirc; possa entrar em contato conosco para exercer seus direitos de titular: E-mail e Correspond&ecirc;ncia.</span></p>
                    <p class="c0"><span class="c1"></span></p>
                    <p class="c2"><span class="c1">Caso tenha d&uacute;vidas sobre esta Pol&iacute;tica de Privacidade ou sobre os dados pessoais que tratamos, voc&ecirc; pode entrar em contato com o nosso Encarregado de Prote&ccedil;&atilde;o de Dados Pessoais, atrav&eacute;s dos seguintes canais: Jo&atilde;o Rocha contato@jadde.com.br</span></p>
                
                </div>

                <div className="modal-terms-footer">

                    <div className="container-center mt-20">
                        <button onClick={()=>{setModal(false); setCheck((prev)=>({...prev, keepLoggedIn:true}))}} className="btn-form">
                            Aceitar
                        </button>
                    </div>

                    <div className="container-center mt-20">
                        <button onClick={()=>{navigate('/login')}} className="btn-form-outline">
                            Entrar
                        </button>
                    </div>

                </div>
            </div>
        </div>
    )
}