import { BsDatabaseFillCheck, BsThreeDots } from "react-icons/bs"
import { SERVICE_INTERVALS } from "../../constants"
import { useEffect, useState } from "react"
import { SpinnerCircular } from "spinners-react"
import { putService } from "../../functions"
import Validator from "../../../../lib/Validator"
import InputMask from "../../../../lib/InputMask"

export default function ModalUpdateServices({setModal, user, setServicesList, serviceShow})
{

    const [form, setForm] = useState({
        name:'',
        duration: 5,
        value:'',
    })

    const [errors, setErrors] = useState({
        name:'',
        duration:'',
        value:'',
    })

    const [sppiner, setSpinner] = useState(false);

    useEffect(()=>{
        setForm({
            name: serviceShow.name,
            duration: serviceShow.duration,
            value: serviceShow.value
        })
    },[])

    function changeValueForm(value)
    {
        const maskedValue = InputMask.moneybr(value);

        setForm((prev) => ({
            ...prev,
            value: maskedValue
        }))
    }

    async function save()
    {
        setSpinner(true);


        const rules = {
            name: 'required|min:4',
            duration: 'required',
            value: 'required',
        };

        const validator = new Validator(rules);

        validator.setCustomMessages({
            'name.required': 'O nome não pode ser vazio.',
            'name.min': 'O nome deve ter pelo menos 4 caracteres.',
            'duration.required': 'Duração do serviço não pode ser vazio.',
            'value.required': 'Valor do serviço não pode ser vazio.',
        });

        const validationErrors = validator.validate(form);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setSpinner(false);
            return

        } else {
            console.log("Formulário válido, enviar dados:", form);
            setErrors({
            name: '',
            duration: '',
            value: '',
            });
        }

        const services = await putService(form, serviceShow.id);

        console.log(services);

        if(services)
        {
            setSpinner("success");
            setServicesList(services);
            setModal(false);

            setForm({

                name:'',
                duration: 5,
                value:'',
            })
        }

        setSpinner(false);
    }


    return(
        
        <div className="modal-services-container">

            <div className="modal-content">

                <div className="header">
                        <button onClick={()=>{setModal((modal)=>({...modal, updateService:false}))}} className="btn-transparent">
                            <BsThreeDots size={30} color="#000"/>
                        </button>
                </div>

                <div className="main">

                    <h3> Editar serviços:</h3>

                    <div className="form">

                        <div className="form-group">
                            <span>Nome:</span>
                            <input value={form.name} onChange={(e)=>{setForm((prev)=>({...prev, name: e.target.value}))}} placeholder="Digite seu nome" type="text"  />
                            <span className="alert-errors">{errors.name}</span>
                        </div>

                        <div className="form-group">

                            <span>Duração:</span>

                            <select value={form.duration} onChange={(e)=>{setForm((prev)=>({...prev, duration: e.target.value}))}}>

                                {SERVICE_INTERVALS.map((item, index) => {
                                    return(
                                        <option key={index} value={item.value}>{item.name}</option>
                                    )
                                })}

                            </select>
                            <span className="alert-errors">{errors.duration}</span>

                        </div>

                        <div className="form-group">
                            <span>Valor:</span>
                            <input value={form.value} onChange={(e)=>{changeValueForm(e.target.value)}} placeholder="Digite o valor" type="text"  />
                            <span className="alert-errors">{errors.value}</span>
                        </div>

                        <div className="form-group">
                            <button onClick={()=>{save()}}>
                                {sppiner === true ? <SpinnerCircular size={20}/> : sppiner === "success" ? <><BsDatabaseFillCheck size={20}/> Registro salvo com sucesso! </> :  "Salvar"}
                            </button>
                        </div>

                    </div>
                    <div className="space-mobile"></div>
                </div>

                <div className="footer">
                    
                </div>

            </div>

        </div>
    )
}