import { useEffect, useState } from "react"
import { BsThreeDots, BsPlusCircleFill, BsDashCircleFill } from "react-icons/bs"
import InputMask from "../../../../lib/InputMask";
import { SpinnerCircular } from "spinners-react";

export default function ModalIntervals({setIsModalOpen, active, setForm, formIntervals, setFormIntervals})
{

    const [inputs, setInputs] = useState({
        count: 1,
        valores: [{ inicio: '', fim: '' }],
    });

    const [sppiner, setSppiner] = useState(false);

    useEffect(()=>{

        console.log('modal intervals');
        console.log(formIntervals);

        formIntervals.map((item, index)=>{

            //console.log('count: ' + item.intervals.length);
            //console.log('name: ' + item.name);
            //console.log('short: ' + active.intervalDay.short);

            if(item.intervals.length > 0 && item.name === active.intervalDay.short)
            {
                let arr = [];
                item.intervals.forEach(element => {
                    arr.push(element)
                });

                setInputs((prev) => ({
                    ...prev,
                    count: item.intervals.length,
                    valores: arr
                }))

                console.log(arr);
            }else{

                console.log('Não existe dados ainda neste dia: ' + active.intervalDay.name);
            }
        })

        

    },[])

    // Função para adicionar mais inputs
    const adicionarInput = () => {
        setInputs(prevState => ({
            ...prevState,
            count: prevState.count + 1,
            valores: [...prevState.valores, { inicio: '', fim: '' }],
        }));
    };

    // Função para remover inputs
    const removerInput = () => {
        setInputs(prevState => ({
            ...prevState,
            count: Math.max(prevState.count - 1, 1),
            valores: prevState.valores.slice(0, -1), // Remove o último item do array de valores
        }));
    };

    // Função para atualizar os valores dos inputs
    const handleChange = (index, e) => {
        const { name, value } = e.target;
        setInputs(prevState => {
            const novosValores = [...prevState.valores];

            const maskedValue = InputMask.time(value);

            novosValores[index][name] = maskedValue;
            return {
                ...prevState,
                valores: novosValores,
            };
        });
    };

    // Array para armazenar os inputs
    const inputsArray = [];
    for (let i = 0; i < inputs.count; i++) {
        // Verifica se é o último input para decidir qual botão exibir
        const isUltimoInput = i === inputs.count - 1;

        inputsArray.push(
            <div key={i} className="item-flex">
                <input
                    name="inicio"
                    placeholder="00:00"
                    value={inputs.valores[i].inicio}
                    onChange={e => handleChange(i, e)}
                />
                <span>às</span>
                <input
                    name="fim"
                    placeholder="00:00"
                    value={inputs.valores[i].fim}
                    onChange={e => handleChange(i, e)}
                />
                {isUltimoInput ? (
                    <button onClick={adicionarInput}>
                        <BsPlusCircleFill size={30} color="#4299e1" />
                    </button>
                ) : (
                    <button onClick={removerInput}>
                        <BsDashCircleFill size={30} color="#f56565" />
                    </button>
                )}
            </div>
        );
    }


    function save()
    {
        setSppiner(true);

        const updatedFormIntervals = formIntervals.map((day) => {
            if (active.intervalDay && day.name === active.intervalDay.short) {
                return {...day, intervals: [...inputs.valores]};
            }
            return day;
        });
        
        setFormIntervals(updatedFormIntervals);

        setForm((prev) =>({
            ...prev,
            intervals: inputs.valores
        }))

        setTimeout(() => {
            setIsModalOpen((prev)=>({
                ...prev,
                intervals: false
            }))
        }, 1000);
    }



    return(

        <div className="modal-intervals-container">
            <div className="modal-content">

                <div className="header">
                    <button onClick={()=>{setIsModalOpen((modal)=>({...modal, intervals:false}))}} className="btn-transparent">
                        <BsThreeDots size={30} color="#000"/>
                    </button>
                </div>

                <div className="main">

                    <div className="item-block">
                        <h3>Intervalos - {active.intervalDay.name}</h3>
                    </div>

                    {inputsArray}

                </div>

                <div className="footer">
                    <button onClick={()=>{save()}}>
                        {sppiner === true ? <SpinnerCircular size={20}/> : "SALVAR"}
                    </button>
                </div>

            </div>
        </div>
    )
}