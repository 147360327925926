import axios from "axios";
import { API_URL } from "../../config";

export async function register(form) {
    try {
        const response = await axios.post(`${API_URL}/register-web`, form);
        return response.data; // Retorna os dados se a requisição for bem-sucedida
    } catch (error) {
        if (error.response) {
            // O servidor respondeu com um status de erro (por exemplo, 4xx ou 5xx)
            console.error('Error response:', error.response.data);
            return error.response.data; // Retorna os dados de erro do servidor
        } else if (error.request) {
            // A requisição foi feita, mas não houve resposta do servidor
            console.error('No response from server:', error.request);
            return { error: 'No response from server' }; // Retorna uma mensagem de erro genérica
        } else {
            // Ocorreu um erro durante a configuração da requisição ou algo fora do esperado
            console.error('Request setup error:', error.message);
            return { error: 'Request setup error' }; // Retorna uma mensagem de erro genérica
        }
    }
}

export function verifieldName(name) {
    const parts = name.trim().split(/\s+/); // Usa expressão regular para dividir por um ou mais espaços
    return parts.length >= 2 && parts.every(part => part.match(/^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]+$/));
    // Verifica se cada parte contém apenas letras (inclui letras acentuadas)
}

