import { BsCalendar2, BsCircle, BsCircleFill, BsFillPersonCheckFill, BsPencilSquare, BsPersonSlash, BsThreeDots, BsXLg } from "react-icons/bs"
import InputMask from "../../../lib/InputMask"
import { formatServiceList } from "../../../share/functions"
import { formatDateTime, formatObjInDate, genereteTimes, getBlock, getMonthDates, getOfficeHourAdminAndCollaborator, putScheduling, schedulingCancel } from "../functions";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { isTodayBeforeOrEqualToGivenDate } from "./functions";
import { SpinnerCircular } from "spinners-react";


export default function ModalSchedulig({

    modalSchedulingOpen,
    setModalSchedulingOpen,
    scheduleShow,
    changeSlotsDate,
    calendarDate,
    changeCalendar,
    selectDateNow,
    daysToMonthList
})

{

    const [timesSlotsList, setTimesSlotsList] = useState([]);
    const [selectDate, setSelectDate] = useState(null);
    const [selectDay, setSelectDay] = useState(null);
    const [modalCalendarOpen, setModalCalendarOpen] = useState(false);
    const [selectMonthString, setSelectMonthString] = useState('');
    const [spinner, setSpinner] = useState({
        save: false,
        cancel: false
    });
    const carrousselRef = useRef(null);
    const itemRefs = useRef({});
    const [states, setStates] = useState({
        date: false,
        time: false,
        services: false,
        value: false,
        buttonChange: false,
        block: false
    })
    const [renderForm, setRenderForm] = useState({
        dateTime:'',
        time: '',
        valueRender:'',
        valueForm:'',
        services: []
    })
    const [piker, setPiker] = useState({
        services: []
    })
    const [list, setList] = useState({
        services: []
    })

    useEffect(() => {
        // Se um dia está selecionado e o item correspondente existe
        if (selectDay && itemRefs.current[selectDay]) {
            const selectedItem = itemRefs.current[selectDay];
            const carroussel = carrousselRef.current;
    
            // Calcula a posição de scroll necessário para que o item selecionado esteja visível
            const scrollLeft = selectedItem.offsetLeft + selectedItem.offsetWidth / 2 - carroussel.offsetWidth / 2;
            
            // Ajusta o scroll do container do carrossel
            carroussel.scrollLeft = scrollLeft;
            }
        }, [selectDate, states.date]);

    useEffect(()=>{

        loadTimesSlots();

        setRenderForm((prev)=>({
            ...prev,
            dateTime: scheduleShow.dateTime,
            time: scheduleShow.time,
            valueRender: scheduleShow.value,
            valueForm: scheduleShow.value,
            services: scheduleShow.services
        }))

        if(scheduleShow.clientBlock !== null)
        {
            setStates((...prev) => ({
                ...prev,
                block: true
            }))
        }

    }, [])

    function includeIdServices(data)
    {
        const arr = [];

        data.map(item =>{
            arr.push(item.id);
        })

        return arr;
    }

    async function loadTimesSlots()
    {
        const officeHourAdminAndCollaborator = await getOfficeHourAdminAndCollaborator(scheduleShow.collaboratorId);
        const arrOfficeHourCollaborator = JSON.parse(officeHourAdminAndCollaborator.OfficeHourCollaborator);
        const arrOfficeHourAdmin = officeHourAdminAndCollaborator.OfficeHourAdmin;

        setList((prev)=>({
            ...prev,
            services: JSON.parse(officeHourAdminAndCollaborator.Services)
        }))

        setPiker((prev) => ({
            ...prev,
            services: includeIdServices(scheduleShow.services)
        }))

        if(selectDateNow)
        {
           
            setSelectDate(selectDateNow);
            const timesSlots = genereteTimes(
                selectDateNow.dayOfWeek.substring(0,3), 
                arrOfficeHourCollaborator,
                officeHourAdminAndCollaborator.SchedulingsCollaborator, 
                arrOfficeHourAdmin);
            setSelectDay(selectDateNow.day);
            setTimesSlotsList(timesSlots);

            return
        }
        if(calendarDate)
        {
            setSelectDate(calendarDate);
            const daysToMonth = getMonthDates(calendarDate.year, calendarDate.month);
            setSelectDay(calendarDate.day)
            setTimesSlotsList(daysToMonth);
            return            
        }
        
    }

    async function cancel(id)
    {
        setSpinner((prev) => ({
            ...prev,
            cancel: true
        }))

        const response = await schedulingCancel(id);

        console.log(response)

        if(calendarDate)
        {
            changeCalendar(calendarDate);
            setModalSchedulingOpen(false);
            setSpinner((prev) => ({
                ...prev,
                cancel: false
            }))
            return
        }
        if(selectDateNow)
        {
            changeSlotsDate(selectDateNow);
            setModalSchedulingOpen(false);
            setSpinner((prev) => ({
                ...prev,
                cancel: false
            }))
            return
        }
    }

    async function changeDateSlot(date)
    {

        setStates((prev) => ({
            ...prev,
            buttonChange: true
        }))

        const officeHourAdminAndCollaborator = await getOfficeHourAdminAndCollaborator(scheduleShow.collaboratorId);
        const arrOfficeHourCollaborator = JSON.parse(officeHourAdminAndCollaborator.OfficeHourCollaborator);
        const arrOfficeHourAdmin = officeHourAdminAndCollaborator.OfficeHourAdmin;

        const dateNowFormated = {
            dayOfWeek: date.name,
            day: date.day,
            month: date.month,
            year: date.year
        };

        setSelectDate(dateNowFormated);

        setRenderForm((prev)=>({
            ...prev,
            dateTime: `${dateNowFormated.year}-${dateNowFormated.month}-${dateNowFormated.day} ${scheduleShow.time}`
        }));
        console.log('teste5',             arrOfficeHourAdmin       )
        const timesSlots = genereteTimes(
            dateNowFormated.dayOfWeek.substring(0,3), 
            arrOfficeHourCollaborator,
            officeHourAdminAndCollaborator.SchedulingsCollaborator, 
            arrOfficeHourAdmin);

        setTimesSlotsList(timesSlots);
        setSelectDay(dateNowFormated.day)
        setTimesSlotsList(timesSlots);
        return
    }

    function changeTime(time)
    {
        setStates((prev) => ({
            ...prev,
            buttonChange: true
        }))

        setRenderForm((prev)=>({
            ...prev,
            time: time
        }))
    }

    function changeServices(item)
    {
        setStates((prev) => ({
            ...prev,
            buttonChange: true
        }))

        setRenderForm(prev => {
            const itemIndex = prev.services.findIndex(t => t.id === item.id); // Encontre o índice do item através do ID
            let array;
        
            if (itemIndex > -1) {
                // Se o item com o mesmo ID já estiver presente, remova-o usando o índice encontrado
                array = [
                    ...prev.services.slice(0, itemIndex),
                    ...prev.services.slice(itemIndex + 1),
                ];
            } else {
                // Se o item com o mesmo ID não estiver presente, adicione-o
                array = [...prev.services, item];
            }
        
            return { ...prev, services: array };
        });

        setPiker(prev => {
            const array = prev.services.includes(item.id)
                ? prev.services.filter(t => t !== item.id) // Remove o item se já estiver presente.
                : [...prev.services, item.id]; // Adiciona o item se não estiver presente.
    
            return { ...prev, services: array };
        });


    }

    function converterMoedaParaNumero(valorMoeda) {
        // Remove o símbolo de R$ (e espaços extras) e substitui vírgula por ponto
        let numeroSemFormatacao = valorMoeda.replace('R$', '').trim().replace(',', '.');
      
        // Converte a string para um número em ponto flutuante
        let valorNumerico = parseFloat(numeroSemFormatacao);
      
        // Retorna o valor numérico, ou zero se a conversão falhou
        return isNaN(valorNumerico) ? 0 : valorNumerico;
    }

    function changeValueForm(value)
    {
        setStates((prev) => ({
            ...prev,
            buttonChange: true
        }))

        const maskedValue = InputMask.moneybr(value);

        setRenderForm((prev)=>({
            ...prev,
            valueForm: maskedValue,
            valueRender: converterMoedaParaNumero(maskedValue)
        }))
    }

    async function save()
    {
        setSpinner((prev) => ({
            ...prev,
            save: true
        }))

        const dateFormated = {
            name: selectDate.dayOfWeek,
            day: selectDate.day,
            month: selectDate.month,
            year: selectDate.year
        };

        if(renderForm.services.length < 1)
        {
            setSpinner((prev) => ({
                ...prev,
                save: false
            }))
            return
        }

        if(renderForm.valueForm === '' || renderForm.valueForm === ' ')
        {
            setSpinner((prev) => ({
                ...prev,
                save: false
            }))

            return
        }

        const DATA = {
            hour: {date: formatObjInDate(dateFormated), time: renderForm.time},
            appointmentDate: formatDateTime(dateFormated, renderForm.time),
            services: piker.services,
            value: renderForm.valueForm
        }

        const response = await putScheduling(DATA, scheduleShow.id);

        console.log(response);

        if(calendarDate)
        {
            changeCalendar(calendarDate);
            setModalSchedulingOpen(false);
            setSpinner((prev) => ({
                ...prev,
                save: false
            }))
            return
        }
        if(selectDateNow)
        {
            changeSlotsDate(selectDateNow);
            setModalSchedulingOpen(false);
            setSpinner((prev) => ({
                ...prev,
                save: false
            }))
            return
        }
    }

    async function changeBlock()
    {

        await getBlock(scheduleShow.clientId);

        setStates((...prev) => ({
            ...prev,
            block: !states.block
        }))
    }

    const openWhatsapp = (number) => {
        // Substitua pelo número de telefone, garantindo o código do país sem adicionar zeros, parênteses ou traços
        // Neste caso, '55' é o código do país para o Brasil seguido do DDD e do número de telefone
        window.open(`https://api.whatsapp.com/send?phone=${number}`, '_blank');
    };


    return(

        <div className={modalSchedulingOpen === false ? "hidden" : "modal"}>

            <div className={modalSchedulingOpen === false ? "hidden" : "modal-lg-bottom-container"}>

                <div className="modal-content">

                    <div className="header-flex-center">
                        <button onClick={()=>{setModalSchedulingOpen(false)}} className="btn-transparent">
                            <BsThreeDots size={30} color="#000"/>
                        </button>
                    </div>

                    <div className="d-flex-start p-10">

                        <div className="d-flex-start">
                            <span className="f-bold text-color-blue-500">Nome do cliente: </span>
                            <span>{scheduleShow.clientName}</span>
                        </div>

                        <div>
                            <button onClick={()=>{changeBlock()}} className={!states.block ? "btn-block-active" : "btn-block-inative"}>
                                {!states.block 
                                    ? <> Bloq. <BsPersonSlash size={25} color="#e53e3e"  /> </>
                                    : <> Desb. <BsFillPersonCheckFill size={25} color="#48bb78" /></>}
                            </button>
                        </div>
                        
                    </div>

                    <div className="d-flex-start p-10">

                        <div className="d-flex-start">
                            <span className="font-bold text-color-blue-500">Data: <span className="font-normal text-color-gray-500">{InputMask.formatDateTimeApontament(renderForm.dateTime).date}</span></span>
                        </div>
                        
                        <div className="">
                            {scheduleShow.status === "Pendente" &&
                                <button onClick={()=>{setStates((prev) => ({...prev, date: !states.date}))}} className="btn-transparent">
                                    {states.date 
                                    ? <BsXLg size={25} color="#d69e2e"  /> 
                                    : <BsPencilSquare size={25} color="#d69e2e" />}
                                </button>
                            }
                        </div>

                    </div>

                    {states.date &&
                    <>
                    {/*
                        <div className="container-date-select">

                            <button onClick={()=>{setModalCalendarOpen(true)}}>
                                <BsCalendar2 size={25} color="#047C54"/>
                                <h3 className="ml-20">{"Abril"}</h3>
                            </button>

                        </div>
                    */}
                        <div ref={carrousselRef} className="carrousel-scheduling">
                            {daysToMonthList.map((item, index) =>{
                                if(isTodayBeforeOrEqualToGivenDate(item) === true)
                                {
                                    return(
                                        <div 
                                        ref={el => itemRefs.current[item.day] = el} 
                                        key={index} 
                                        className={selectDay === item.day ? "item-date-active" : "item-date"}
                                        onClick={()=>{changeDateSlot(item)}}
                                        >
                                            <div className="item-date-content">
                                                <p>{item.name}</p>
                                                <p>{item.day} / {item.month}</p>
                                            </div>
                                        </div>
                                        )   
                                }

                                })}
                        </div>
                    </>
                    }

                    <div className="d-flex-start p-10">

                        <div className="d-flex-start">
                            <span className="font-bold text-color-blue-500">Horário: 
                                <span className="font-normal text-color-gray-500">{renderForm.time}</span>
                            </span>
                        </div>
                       
                        <div>
                            {scheduleShow.status === "Pendente" &&
                                <button onClick={()=>{setStates((prev) => ({...prev, time: !states.time}))}} className="btn-transparent">
                                    {states.time 
                                    ? <BsXLg size={25} color="#d69e2e"  /> 
                                    : <BsPencilSquare size={25} color="#d69e2e" />}
                                </button>                            
                            }
                        </div>

                    </div>

                    {states.time &&
                    
                        <div className="times-slots-schedulins">

                            {timesSlotsList.map((item, index) => {

                                    if(item.slot === scheduleShow.time && item.available === false)
                                    {
                                        return(
                                            <div onClick={()=>{changeTime(item.slot)}} key={index} className={"item-active"}>{item.slot}</div>
                                        )
                                    }
                                    if (item.slot !== renderForm.time && item.available === true) {
                                        return(
                                            <div onClick={()=>{changeTime(item.slot)}} key={index} className={"item"}>{item.slot}</div>
                                        )
                                    } 
                                    if(item.slot !== renderForm.time && item.available === false) {
                                        return(
                                            <div onClick={()=>{alert("Não disponível")}} key={index} className={"item-inative"}>{item.slot}</div>
                                        )
                                    }
                                    if (item.slot === renderForm.time && item.available === true) {
                                        return(
                                            <div onClick={()=>{changeTime(item.slot)}} key={index} className={"item-active"}>{item.slot}</div>
                                        )
                                    } 

                            })}

                        </div>                    
                
                    }

                    <div className="d-flex-start p-10">
                        <div className="d-flex-start">
                            <span className="font-bold text-color-blue-500">Serviços: <span className="font-normal text-color-gray-500">{formatServiceList(renderForm.services)}</span></span>
                        </div>
                        <div>
                        {scheduleShow.status === "Pendente" &&

                            <button onClick={()=>{setStates((prev) => ({...prev, services: !states.services}))}} className="btn-transparent">
                                {states.services
                                ? <BsXLg size={25} color="#d69e2e"  /> 
                                : <BsPencilSquare size={25} color="#d69e2e" />}
                            </button>                            
                        }
                        </div>
                    </div>

                    {states.services &&

                        <div className="services-schedulins">

                            <ul>
                                {list.services.map((item, index) =>{
                                    return(
                                        <li onClick={()=>{changeServices(item)}} key={index}>
                                            <span>{item.name}</span>
                                            {piker.services.includes(item.id) 
                                            ? <BsCircleFill size={16} color={'#9BDDBA'} /> 
                                            : <BsCircle size={16} color={'#ccc'} />}
                                            
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>                    
                    
                    }

                    

                    <div className="d-flex-start p-10">

                        <div className="d-flex-start">
                            <span className="f-bold text-color-blue-500">Valor</span>
                            <span className="text-color-gray-500">{InputMask.formatStringToMoney(renderForm.valueRender)}</span>
                        </div>
                        <div>
                        {scheduleShow.status === "Pendente" &&

                            <button onClick={()=>{setStates((prev) => ({...prev, value: !states.value}))}} className="btn-transparent">
                                {states.value
                                ? <BsXLg size={25} color="#d69e2e"  /> 
                                : <BsPencilSquare size={25} color="#d69e2e" />}
                            </button>                            
                        }
                        </div>

                    </div>

                    {states.value && 
                        <div className="value-schenduling">
                            <input value={renderForm.valueForm} onChange={(e)=>{changeValueForm(e.target.value)}} placeholder="Informe um valor"/>
                        </div>
                    }

                    <div className="d-flex-start p-10">
                        <span className="f-bold text-color-blue-500">Status</span>
                        <span className={scheduleShow.status === "Pendente" ? "text-color-gray-400" : "text-color-green-400"}>{scheduleShow.status}</span>
                    </div>

                    <div className="d-flex-start p-10">
                        <span className="f-bold text-color-blue-500">WhatsApp</span>
                        <div className="d-flex-start">
                            <span>{scheduleShow.phone}</span>
                            <button onClick={()=>{openWhatsapp("55" + scheduleShow.phone)}} className="btn-transparent">
                                <FaWhatsapp size={25} color="#48bb78" />
                            </button>
                        </div>
                    </div>

                    <div className="p-10">
                        {scheduleShow.status === "Pendente" &&
                        <button className="btn-outline-red" onClick={()=>{cancel(scheduleShow.id)}}>
                           {spinner.cancel ? <SpinnerCircular size={20}/> : "CANCELAR AGENDAMENTO"} 
                        </button>}
                    </div>

                    {states.buttonChange &&
                        <div className="p-10">
                            {scheduleShow.status === "Pendente" &&
                            <button className="btn-green" onClick={()=>{save()}}>
                                {spinner.save ? <SpinnerCircular size={20}/> : "SALVAR MUDANÇAS"}
                            </button>}
                        </div>
                    }
                </div>

            </div>

        </div>
    )
}